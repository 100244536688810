import { SNACKBAR_CHANGED, LOADING_DIALOG_CHANGED } from "../types";

const INITIAL_STATE = {
  snackbarData: {
    open: false,
    message: "",
  },
  loadingDialog: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SNACKBAR_CHANGED:
      return {
        ...state,
        snackbarData: {
          open: action.payload.open,
          message: action.payload.message,
          place: action.payload.place,
          icon: action.payload.icon,
          color: action.payload.color,
        },
      };
    case LOADING_DIALOG_CHANGED:
      return {
        ...state,
        loadingDialog: action.payload,
      };

    default:
      return state;
  }
};
