import React from "react";
import {
  getPreviewData,
  getPropertiesOfUser,
  payThePayment,
  saveMembership,
  updateProperties,
  renewMembership,
  getPackagesByUser,
  getUserPackageTimesByCourseJoinerId,
} from "../../services/membership";
import {
  getPrograms,
  upgradeProgramOrder,
  downgradeProgramOrder,
  deleteProgramOfUser,
  createProgram,
} from "services/program";
import SnackBar from "../../helpers/SnackbarHelper";
import Button from "components/CustomButtons/Button.js";
import store from "store";
import { dateToSqlDate } from "helpers/Utils";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";
import { getDayLanguageKey } from "helpers/Utils";
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";

export const updateAccountInfo = async (userInfo, isChanged, language) => {
  const languageType = store.getState().SettingResponse.languageType;
  if (!isChanged) {
    SnackBar.showSnackbar(language.noChange, SnackBar.colors.warning);
    return false;
  }
  const updated = {
    name: userInfo.name,
    // surname: userInfo.surname,
    email: userInfo.email,
    password: userInfo.password,
    phone: userInfo.phone,
    appUser: userInfo.appUser,
    phoneCountry: userInfo.phoneCountry
  };
  const response = await APIEndpointHelper.updateUser(userInfo._id, updated);
  const color = response.success
    ? SnackBar.colors.success
    : SnackBar.colors.danger;
  SnackBar.showSnackbar(response.Message[languageType] || "", color);
  return true;
};

export const getPropertiesInfo = async (userId) => {
  const response = await getPropertiesOfUser(userId);
  let result;
  if (response.data.Data) result = response.data.Data;
  return result;
};

export const updateMeasuresInfo = async (
  userInfo,
  isChanged,
  language,
  properties
) => {
  try {
    const languageType = store.getState().SettingResponse.languageType;
    if (!isChanged) {
      SnackBar.showSnackbar(language.noChange, SnackBar.colors.warning);
      return false;
    }
    const updated = {};
    properties["id"] = undefined;
    properties["createdOn"] = undefined;
    const keys = Object.keys(properties);
    keys.forEach((key) => {
      if (key === "birthDate") {
        updated[key] = properties[key];
      } else if (properties[key]) {
        updated[key] = parseInt(properties[key]);
      }
    });
    const response = await updateProperties(userInfo.userId, updated);
    const color =
      response.Status === 1 ? SnackBar.colors.success : SnackBar.colors.danger;
    SnackBar.showSnackbar(response.Message[languageType], color);
    return true;
  } catch (err) {
    console.log(err);
    SnackBar.showSnackbar(language.unexpectedError, SnackBar.colors.danger);
  }
};

export const previewMembership = async (data, language) => {
  try {
    const languageType = store.getState().SettingResponse.languageType;
    const requestBody = createMembershipDataConversion(data, language);
    if (!requestBody.data && requestBody.message) {
      SnackBar.showSnackbar(requestBody.message, SnackBar.colors.danger);
      return null;
    }
    const response = await getPreviewData(requestBody.data);
    if (response.Data) return response.Data;
    if (response.Status === 4 || response.Status === 3) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.danger
      );
    }
    return null;
  } catch (err) {
    console.log(err);
    SnackBar.showSnackbar(language.unexpectedError, SnackBar.colors.danger);
    return null;
  }
};

const createMembershipDataConversion = (data, language) => {
  if (!data.startDate)
    return { data: null, message: language.invalidStartDate };
  if (!data.endDate) return { data: null, message: language.invalidEndDate };
  if (data.endDate.getTime() < data.startDate.getTime())
    return { data: null, message: language.smallEndDateError };
  if (!data.paymentDate && !data.installment && !data.isFirstInstallmentPayed)
    return { data: null, message: language.paymentDateError };
  if (data.startDate.getMonth() === data.endDate.getMonth()) {
    return { data: null, message: language.lastOneMonthError };
  }
  if (isNaN(parseInt(data.amount)))
    return { data: null, message: language.invalidAmount };
  let returnedData = {
    startDate: dateToSqlDate(data.startDate),
    endDate: dateToSqlDate(data.endDate),
    installment: data.installment ? 1 : 0,
    amount: parseInt(data.amount),
    isFirstInstallmentPayed: data.isFirstInstallmentPayed ? true : false,
    user: data.user,
    paymentDate:
      data.paymentDate && !data.installment && !data.isFirstInstallmentPayed // Taksitli değilse ve ödeme yapılamdıysa ne zaman ödeneceği girilmelidir.
        ? dateToSqlDate(data.paymentDate)
        : undefined,
    renew: data.renew,
  };
  return { data: returnedData, message: "" };
};

export const getHeaderTitleForPaymentPreview = (language) => {
  return [
    language.amount,
    language.paymentDate,
    language.lastPaymentDate,
    language.status,
  ];
};

export const getHeaderTitleForPackagesTable = (language) => {
  return [language.packageName, language.startDate, language.endDate, " "];
};

export const getRowsOfPreviewPayment = (payments, onPressPay, preview) => {
  const language = store.getState().SettingResponse.language.userProfile;
  const shownProperties = ["amount", "paymentDate", "lastPaymentDate", "payed"];
  const rows = [];
  payments.forEach((item) => {
    const row = [];
    shownProperties.forEach((property) => {
      let addedProp = item[property];
      if (
        (property === "paymentDate" || property == "lastPaymentDate") &&
        addedProp
      )
        addedProp = moment(addedProp).format("Do MMMM YY");
      if (!addedProp && property === "paymentDate") {
        addedProp = "-";
      }
      if (property === "payed") {
        if (!addedProp) {
          addedProp = !preview ? (
            <Button onClick={() => onPressPay(item)} round color="danger">
              {language.pay}
            </Button>
          ) : (
            <p style={{ color: "red" }}>{language.notPayed}</p>
          );
        } else addedProp = <p style={{ color: "green" }}>{language.payed}</p>;
      }
      row.push(addedProp);
    });
    row.push(item);
    rows.push(row);
  });
  return rows;
};

export const createMembership = async (preview, language, fromRenew) => {
  const languageType = store.getState().SettingResponse.languageType;
  try {
    const body = {
      membership: preview.membership,
      payments: preview.payments,
    };
    let response;
    if (fromRenew) {
      response = await renewMembership(body);
    } else response = await saveMembership(body);

    if (response.Status === 1) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.success
      );
      return true;
    }
    if (response.Status === 4 || response.Status === 3) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.danger
      );
      return false;
    }
  } catch (err) {
    console.log(err);
    SnackBar.showSnackbar(language.unexpectedError, SnackBar.colors.danger);
    return false;
  }
};

export const signAsPay = async (paymentId, language) => {
  const languageType = store.getState().SettingResponse.languageType;
  try {
    const response = await payThePayment(paymentId);
    if (response.Status === 1) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.success
      );
      return true;
    }
    if (response.Status === 4 || response.Status === 3) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.danger
      );
      return false;
    }
  } catch (err) {
    SnackBar.showSnackbar(language.unexpectedError, SnackBar.colors.danger);
    return false;
  }
};

export const getUserInfo = async (userId) => {
  if (!userId) return null;
  const response = await APIEndpointHelper.getUserInfo(userId);
  if (response.success) return response.data;
  return null;
};

export const getProgramsOfUser = async (userId) => {
  const programs = await getPrograms(userId);
  if (!programs) return [];
  return programs;
};

export const getHeaderTitleForProgramPreview = (language) => {
  return [language.programName, language.order, language.order, "", ""];
};

export const getRowsOfPrograms = (
  programs,
  language,
  onPressUp,
  onPressDown,
  onPressDelete,
  onPressDetail
) => {
  const shownProperties = ["name", "up", "down", "delete", "detail"];
  const rows = [];
  programs.forEach((item) => {
    const row = [];
    shownProperties.forEach((property) => {
      let addedProp = item[property];
      if (!addedProp && property === "up") {
        addedProp = <KeyboardArrowUp onClick={() => onPressUp(item)} />;
      }
      if (!addedProp && property === "down") {
        addedProp = <KeyboardArrowDown onClick={() => onPressDown(item)} />;
      }
      if (!addedProp && property === "delete") {
        addedProp = (
          <Button onClick={() => onPressDelete(item)} round color="danger">
            {language.delete}
          </Button>
        );
      }
      if (!addedProp && property === "detail") {
        addedProp = (
          <Button onClick={() => onPressDetail(item)} round color="info">
            {language.detail}
          </Button>
        );
      }
      row.push(addedProp);
    });
    row.push(item);
    rows.push(row);
  });
  return rows;
};

export const upgradeDownGradeOrderOfProgram = async (program, type) => {
  const languageType = store.getState().SettingResponse.languageType;
  const language = store.getState().SettingResponse.language.userProfile;
  try {
    let response;
    if (type === "upgrade") response = await upgradeProgramOrder(program.id);
    else response = await downgradeProgramOrder(program.id);
    if (response.Status === 1) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.success
      );
      return true;
    }
    if (response.Status === 4 || response.Status === 3) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.danger
      );
      return false;
    }
  } catch (err) {
    SnackBar.showSnackbar(language.unexpectedError, SnackBar.colors.danger);
    return false;
  }
};

export const deleteProgram = async (program) => {
  const languageType = store.getState().SettingResponse.languageType;
  const language = store.getState().SettingResponse.language.userProfile;
  try {
    const response = await deleteProgramOfUser(program.id);
    if (response.Status === 1) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.success
      );
      return true;
    }
    if (response.Status === 4 || response.Status === 3) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.danger
      );
      return false;
    }
  } catch (err) {
    SnackBar.showSnackbar(language.unexpectedError, SnackBar.colors.danger);
    return false;
  }
};

export const createProgramForUser = async (programData) => {
  const languageType = store.getState().SettingResponse.languageType;
  const language = store.getState().SettingResponse.language.userProfile;
  try {
    const response = await createProgram(programData);
    if (response.Status === 1) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.success
      );
      return true;
    }
    if (response.Status === 4 || response.Status === 3) {
      SnackBar.showSnackbar(
        response.Message[languageType],
        SnackBar.colors.danger
      );
      return false;
    }
  } catch (err) {
    SnackBar.showSnackbar(language.unexpectedError, SnackBar.colors.danger);
    return false;
  }
};

export const getUserPackagesForTable = async (
  userId,
  onPressTimes,
  classes
) => {
  const { data } = await getPackagesByUser(userId);
  const language = store.getState().SettingResponse.language.userProfile;

  const shownProperties = ["packageName", "startDate", "endDate"];
  const rows = [];
  if (!data?.Data) {
    return rows;
  }
  data.Data.forEach((item) => {
    const row = [];
    shownProperties.forEach((property) => {
      let addedProp = item[property];
      if (property === "startDate" || property === "endDate") {
        addedProp = moment(addedProp).format("Do MMMM YYYY");
      }

      row.push(addedProp);
    });
    const timesButton = (
      <Tooltip
        id="tooltip-top-start"
        title={language.joinTimes}
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          onClick={() => onPressTimes(item)}
          aria-label="Close"
          className={classes.tableActionButton}
        >
          <AccessTime
            className={classes.tableActionButtonIcon + " " + classes.info}
          />
        </IconButton>
      </Tooltip>
    );
    row.push(timesButton);
    row.push(item);
    rows.push(row);
  });
  return rows;
};

export const getUserPackageTimes = async (userId, courseJoinerId) => {
  const language = store.getState().SettingResponse.language.courses;
  const { data } = await getUserPackageTimesByCourseJoinerId(
    userId,
    courseJoinerId
  );

  const shownProperties = ["day", "start_time", "end_time"];
  const rows = [];
  if (!data?.Data) {
    return rows;
  }
  data.Data.forEach((item) => {
    const row = [];
    shownProperties.forEach((property) => {
      let addedProp = item[property];

      if (property === "day") {
        addedProp = language[getDayLanguageKey(addedProp)];
      }
      row.push(addedProp);
    });

    row.push(item);
    rows.push(row);
  });
  return rows;
};

export const getUserPackageJoinTimesHeaderTitlesByLanguage = () => {
  const language = store.getState().SettingResponse.language.courses;
  return [language.day, language.startTime, language.endTime];
};
