/* eslint-disable react-hooks/exhaustive-deps */
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";

export default function DayMonthYear({ question, onSelect, answer }) {
  const onChangeDay = (day) => {
    onSelect?.(
      question.key,
      `${day}|${answer?.feedback[1] || "0"}|${answer?.feedback[2] || "0"}`
    );
  };

  const onChangeMonth = (month) => {
    onSelect?.(
      question.key,
      `${answer?.feedback[0] || "0"}|${month}|${answer?.feedback[2] || "0"}`
    );
  };

  const onChangeYear = (year) => {
    onSelect?.(
      question.key,
      `${answer?.feedback[0] || "0"}|${answer?.feedback[1] || "0"}|${year}`
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={4} md={4}>
        <CustomInput
          labelText={"Gün"}
          id={`${question.key}_min`}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: answer?.feedback[0],
            onChange: (e) => onChangeDay(e.target.value),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={4} md={4}>
        <CustomInput
          labelText={"Ay"}
          id={`${question.key}_min`}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: answer?.feedback[1],
            onChange: (e) => onChangeMonth(e.target.value),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={4} md={4}>
        <CustomInput
          labelText={"Yıl"}
          id={`${question.key}_min`}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: answer?.feedback[2],
            onChange: (e) => onChangeYear(e.target.value),
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
