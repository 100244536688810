import Picker from "components/CustomPicker/CustomPicker";
import React from "react";
import { JOB_KEY } from "../useDynamicJobHook";

export default function MultiSelect({ question, answer, onSelect }) {
  const selectorData =
    question.choices?.map((item) => ({ value: item.key, label: item.label })) ||
    [];

  const onSelectPicker = (newSelected) => {
    onSelect?.(question.key, newSelected);
  };

  return (
    <Picker
      label={question.sheetTitle}
      id={question.key}
      data={selectorData}
      value={answer?.feedback || []}
      multiple
      onChange={(e) => onSelectPicker(e.target.value)}
    />
  );
}
