import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const options = {
  keyPrefix: process.env.REACT_APP_AWS_UPLOAD_PREFIX,
  bucket: process.env.REACT_APP_AWS_BUCKET,
  region: process.env.REACT_APP_AWS_REGION,
  successActionStatus: 201,
};

let credentials = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};
const client = new S3Client({
  region: options.region,
  credentials: credentials,
});

const AWSHelper = {
  uploadFile: async function (blob, name) {
    try {
      const response = await client.send(
        new PutObjectCommand({
          Bucket: options.bucket,
          Key: options.keyPrefix + name,
          Body: blob,
          ACL: "public-read",
        })
      );
      if (
        response.$metadata.httpStatusCode === 200 ||
        response.$metadata.httpStatusCode === 201
      ) {
        return `${process.env.REACT_APP_AWS_URL}/${process.env.REACT_APP_AWS_UPLOAD_PREFIX}${name}`;
      }
      return null;
    } catch (error) {
      return null;
    }
  },
};

export default AWSHelper;
