import React, { useEffect, useState } from "react";
// @material-ui/core components
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from "components/Dialog/Dialog";
import { useSelector } from "react-redux";
import { updateAccountInfo } from "./helper";
import GenericCard from "components/GenericCard/GenericCard";
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import SnackBar from "helpers/SnackbarHelper";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import CustomPhoneInput from "components/CustomPhoneInput/CustomPhoneInput";
import validatePhoneNumber from "helpers/validatePhoneNumber";
const useStyles = makeStyles(styles);

export default function AccountComponent({ account, refresh, details }) {
  const language = useSelector(
    (r_state) => r_state.SettingResponse.language.userProfile
  );
  const classes = useStyles();

  const [userinfo, setUserinfo] = useState(account);
  const [userInfoChanged, setUserInfoChanged] = useState(false);
  const [acountDialogOpen, setAccountDialogOpen] = useState(false);
  const [gdprLoading, setGdprLoading] = useState(false);
  const [isUserDeleteLoading, setIsUserDeleteLoading] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  useEffect(() => {
    setPhoneNumber(`${userinfo.phoneCountry}${userinfo.phone}`);
    setCountryCode(userinfo.phoneCountry);
  }, [userinfo.phone, userinfo.phoneCountry]);

  const history = useHistory();

  const handleOnPhoneChange = (value, country) => {
    setUserInfoChanged(true);
    setPhoneNumber(value);
    setCountryCode(country.dialCode);
  };
  const onPressForm = () => {
    history.push("/admin/internal/form", { userinfo, userDetails: details });
  };

  const handleAccountInfoChange = (key, value) => {
    const newUserInfo = { ...userinfo };
    newUserInfo[key] = value;
    setUserinfo(newUserInfo);
    setUserInfoChanged(true);
  };

  const pressUpdateConfirm = () => {
    const phoneValid =
      phoneNumber && validatePhoneNumber(`+${phoneNumber}`).isValid;

    const strippedPhoneNumber =
      phoneValid && phoneNumber.startsWith(countryCode)
        ? phoneNumber.slice(countryCode?.length || 0)
        : phoneNumber;

    setAccountDialogOpen(false);
    updateAccountInfo(
      {
        ...userinfo,
        phone: phoneValid ? strippedPhoneNumber : undefined,
        phoneCountry: countryCode,
      },
      userInfoChanged,
      language
    );
    refresh?.();
  };

  const updateUserDialogButtons = [
    {
      key: "no",
      label: language.no,
      onClick: () => setAccountDialogOpen(false),
    },
    {
      key: "yes",
      label: language.yes,
      onClick: pressUpdateConfirm,
    },
  ];

  const onPressUpdateAccount = () => {
    setAccountDialogOpen(true);
  };

  const onPressApproveReject = async () => {
    if (userinfo.status !== "confirmed") {
      await APIEndpointHelper.approveAccount(userinfo._id);
    } else {
      await APIEndpointHelper.rejectAccount(userinfo._id);
    }
    await refresh?.();
  };

  const onPressActivePassive = async () => {
    if (details?.status !== "active") {
      if (userinfo.role === "provider") {
        await APIEndpointHelper.activateAccount(userinfo._id);
      } else {
        await APIEndpointHelper.activateSearcher(userinfo._id);
      }
    } else {
      if (userinfo.role === "provider") {
        await APIEndpointHelper.deactivateAccount(userinfo._id);
      } else {
        await APIEndpointHelper.deactivateSearcher(userinfo._id);
      }
    }
    await refresh?.();
  };

  const copyToClipboard = (copiedText) => {
    var textField = document.createElement("textarea");
    textField.innerText = copiedText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const getGdprLink = async () => {
    setGdprLoading(true);
    const response = await APIEndpointHelper.createGdprLink(
      process.env.REACT_APP_GDPR_REDIRECT_URI,
      userinfo.email,
      userinfo.phone
    );
    if (response?.data?.link) {
      copyToClipboard(response.data.link);
      SnackBar.showSnackbar("Link kopyalandı!", SnackBar.colors.success);
    } else {
      SnackBar.showSnackbar(
        "Beklenmedik hata gerçekleşti.",
        SnackBar.colors.success
      );
    }

    setGdprLoading(false);
  };

  const onPressDeleteUser = async () => {
    setIsUserDeleteLoading(true);
    try {
      await APIEndpointHelper.deleteUser(userinfo._id);
      history.push("/members");
    } catch (e) {
      debugger;
      console.log(e);
    } finally {
      setIsUserDeleteLoading(true);
    }
  };

  useEffect(() => {
    setUserinfo(account);
  }, [account]);
  const isPhoneValid =
    phoneNumber && validatePhoneNumber(`+${phoneNumber}`).isValid;
  return (
    <GridItem xs={12} sm={12} md={8}>
      <GenericCard
        title={language.editProfile}
        subtitle={language.editProfileSubtitle}
        renderFooter={() => (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                // disabled={!isPhoneValid}
                onClick={onPressUpdateAccount}
                color="primary"
              >
                {language.updateButton}
              </Button>
              <Button
                color={userinfo.status !== "confirmed" ? "success" : "danger"}
                onClick={onPressApproveReject}
              >
                {userinfo.status !== "confirmed"
                  ? language.approveAccount
                  : language.rejectAccount}
              </Button>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              {userinfo.role === "provider" ? (
                <Button onClick={onPressForm}>{"Form Doldur"}</Button>
              ) : null}
              {!userinfo.gdpr && (
                <Button
                  loading={gdprLoading}
                  color={"info"}
                  onClick={getGdprLink}
                >
                  {"KVKK Linki Al"}
                </Button>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color={details?.status !== "active" ? "success" : "danger"}
                onClick={onPressActivePassive}
              >
                {details?.status !== "active"
                  ? language.activateAccount
                  : language.deactivateAccount}
              </Button>
              <Button
                loading={isUserDeleteLoading}
                color={"danger"}
                onClick={onPressDeleteUser}
              >
                {language.delete_user}
              </Button>
            </GridItem>
          </GridContainer>
        )}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={language.fullname}
              id="fullname"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.name,
                onChange: (e) =>
                  handleAccountInfoChange("name", e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <CustomInput
              labelText={language.email}
              id="email-address"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.email,
                onChange: (e) =>
                  handleAccountInfoChange("email", e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText={language.password}
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.password,
                onChange: (e) =>
                  handleAccountInfoChange("password", e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <CustomPhoneInput
              value={phoneNumber}
              onChange={handleOnPhoneChange}
            />
            {/* <CustomInput
              labelText={language.phone}
              id="phone"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.phone,
                onChange: (e) =>
                  handleAccountInfoChange("phone", e.target.value),
              }}
            /> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Checkbox
                checked={userinfo.appUser}
                // tabIndex={-1}
                onClick={() =>
                  handleAccountInfoChange("appUser", !userinfo.appUser)
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.root,
                }}
              />
              <p>{language.app_user}</p>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={language.status}
              id="status"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.status,
                enabled: false,
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={language.providerStatus}
              id="provider_status"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value:
                  details?.status === "active"
                    ? language.active
                    : language.passive,
                enabled: false,
                disabled: true,
              }}
            />
          </GridItem>
        </GridContainer>
      </GenericCard>
      <Dialog
        open={acountDialogOpen}
        onClose={() => setAccountDialogOpen(false)}
        title={language.warning}
        description={language.sureToUpdateAccount}
        buttons={updateUserDialogButtons}
      />
    </GridItem>
  );
}

AccountComponent.propTypes = {
  account: PropTypes.any,
};
