import axios from "axios";

const URL = "https://maps.googleapis.com/maps/api/geocode/json";

export const getAddressFromLatLong = async (latitude, longitude) => {
  try {
    const result = await axios.get(
      `${URL}?address=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
    );

    return result.data.results;
  } catch (e) {
    return [];
  }
};
