export const languages = [
    { "code": "tr", "name": "Türkçe" },
    { "code": "en", "name": "İngilizce" },
    { "code": "tk", "name": "Türkmence" },
    { "code": "uz", "name": "Özbekçe" },
    { "code": "az", "name": "Azerice" },
    { "code": "ru", "name": "Rusça" },
    { "code": "aa", "name": "Afar" },
    { "code": "ab", "name": "Abhazca" },
    { "code": "ae", "name": "Avestaca" },
    { "code": "af", "name": "Afrikanca" },
    { "code": "ak", "name": "Akan" },
    { "code": "am", "name": "Amharca" },
    { "code": "an", "name": "Aragonca" },
    { "code": "ar", "name": "Arapça" },
    { "code": "as", "name": "Assamca" },
    { "code": "av", "name": "Avarca" },
    { "code": "ay", "name": "Aymara" },
    { "code": "ba", "name": "Başkırtça" },
    { "code": "be", "name": "Belarusça" },
    { "code": "bg", "name": "Bulgarca" },
    { "code": "bh", "name": "Bihari Dilleri" },
    { "code": "bi", "name": "Bislama" },
    { "code": "bm", "name": "Bambara" },
    { "code": "bn", "name": "Bengalce" },
    { "code": "bo", "name": "Tibetçe" },
    { "code": "br", "name": "Bretonca" },
    { "code": "bs", "name": "Boşnakça" },
    { "code": "ca", "name": "Katalanca" },
    { "code": "ce", "name": "Çeçence" },
    { "code": "ch", "name": "Chamorro" },
    { "code": "co", "name": "Korsikaca" },
    { "code": "cr", "name": "Cree Dili" },
    { "code": "cs", "name": "Çekçe" },
    { "code": "cu", "name": "Kilise Slavcası" },
    { "code": "cv", "name": "Çuvaşça" },
    { "code": "cy", "name": "Galce" },
    { "code": "da", "name": "Danca" },
    { "code": "de", "name": "Almanca" },
    { "code": "dv", "name": "Divehi; Maldivce" },
    { "code": "dz", "name": "Dzongkha" },
    { "code": "ee", "name": "Ewe" },
    { "code": "el", "name": "Yunanca" },
    { "code": "eo", "name": "Esperanto" },
    { "code": "es", "name": "İspanyolca" },
    { "code": "et", "name": "Estonca" },
    { "code": "eu", "name": "Baskça" },
    { "code": "fa", "name": "Farsça" },
    { "code": "ff", "name": "Fula Dili" },
    { "code": "fi", "name": "Fince" },
    { "code": "fj", "name": "Fiji Dili" },
    { "code": "fo", "name": "Faroe Dili" },
    { "code": "fr", "name": "Fransızca" },
    { "code": "fy", "name": "Frisyanca" },
    { "code": "ga", "name": "İrlandaca" },
    { "code": "gd", "name": "İskoçça Gaelce" },
    { "code": "gl", "name": "Galiçyaca" },
    { "code": "gn", "name": "Guarani" },
    { "code": "gu", "name": "Gucaratça" },
    { "code": "gv", "name": "Manca" },
    { "code": "ha", "name": "Hausaca" },
    { "code": "he", "name": "İbranice" },
    { "code": "hi", "name": "Hintçe" },
    { "code": "ho", "name": "Hiri Motu" },
    { "code": "hr", "name": "Hırvatça" },
    { "code": "ht", "name": "Haiti Creole Dili" },
    { "code": "hu", "name": "Macarca" },
    { "code": "hy", "name": "Ermenice" },
    { "code": "hz", "name": "Herero" },
    { "code": "ia", "name": "Interlingua (IAK)" },
    { "code": "id", "name": "Endonezce" },
    { "code": "ie", "name": "Interlingue" },
    { "code": "ig", "name": "İgbo" },
    { "code": "ii", "name": "Sichuan Yi" },
    { "code": "ik", "name": "İnupiakça" },
    { "code": "io", "name": "Ido" },
    { "code": "is", "name": "İzlandaca" },
    { "code": "it", "name": "İtalyanca" },
    { "code": "iu", "name": "İnuktitut" },
    { "code": "ja", "name": "Japonca" },
    { "code": "jv", "name": "Cava Dili" },
    { "code": "ka", "name": "Gürcüce" },
    { "code": "kg", "name": "Kongo" },
    { "code": "ki", "name": "Kikuyu" },
    { "code": "kj", "name": "Kuanyama" },
    { "code": "kk", "name": "Kazakça" },
    { "code": "kl", "name": "Grönland Dili" },
    { "code": "km", "name": "Orta Khmer Dili" },
    { "code": "kn", "name": "Kannada" },
    { "code": "ko", "name": "Korece" },
    { "code": "kr", "name": "Kanuri" },
    { "code": "ks", "name": "Keşmirce" },
    { "code": "ku", "name": "Kürtçe" },
    { "code": "kv", "name": "Komi" },
    { "code": "kw", "name": "Kernevekçe" },
    { "code": "ky", "name": "Kırgızca" },
    { "code": "la", "name": "Latince" },
    { "code": "lb", "name": "Lüksemburgca" },
    { "code": "lg", "name": "Ganda Dili" },
    { "code": "li", "name": "Limburg Dili" },
    { "code": "ln", "name": "Lingala" },
    { "code": "lo", "name": "Lao Dili" },
    { "code": "lt", "name": "Litvanca" },
    { "code": "lu", "name": "Luba-Katanga" },
    { "code": "lv", "name": "Letonca" },
    { "code": "mg", "name": "Malagasy" },
    { "code": "mh", "name": "Marshallese" },
    { "code": "mi", "name": "Maori Dili" },
    { "code": "mk", "name": "Makedonca" },
    { "code": "ml", "name": "Malayalam" },
    { "code": "mn", "name": "Moğolca" },
    { "code": "mr", "name": "Marathi" },
    { "code": "ms", "name": "Malayca" },
    { "code": "mt", "name": "Malta Dili" },
    { "code": "my", "name": "Birmanca" },
    { "code": "na", "name": "Nauru Dili" },
    { "code": "nb", "name": "Norveççe Bokmål" },
    { "code": "nd", "name": "Kuzey Ndebele" },
    { "code": "ne", "name": "Nepalce" },
    { "code": "ng", "name": "Ndonga" },
    { "code": "nl", "name": "Hollandaca" },
    { "code": "nn", "name": "Norveççe Nynorsk" },
    { "code": "no", "name": "Norveççe" },
    { "code": "nr", "name": "Güney Ndebele" },
    { "code": "nv", "name": "Navajo; Navaho" },
    { "code": "ny", "name": "Nyanja" },
    { "code": "oc", "name": "Oksitanca" },
    { "code": "oj", "name": "Ojibva" },
    { "code": "om", "name": "Oromo Dili" },
    { "code": "or", "name": "Oriya" },
    { "code": "os", "name": "Osetçe" },
    { "code": "pa", "name": "Pencapça" },
    { "code": "pi", "name": "Pali Dili" },
    { "code": "pl", "name": "Lehçe" },
    { "code": "ps", "name": "Peştuca" },
    { "code": "pt", "name": "Portekizce" },
    { "code": "qu", "name": "Keçuva Dili" },
    { "code": "rm", "name": "Romanş Dili" },
    { "code": "rn", "name": "Rundi" },
    { "code": "ro", "name": "Rumence" },
    { "code": "rw", "name": "Kinyarwanda" },
    { "code": "sa", "name": "Sanskrit" },
    { "code": "sc", "name": "Sardunya Dili" },
    { "code": "sd", "name": "Sindhçe" },
    { "code": "se", "name": "Kuzey Samice" },
    { "code": "sg", "name": "Sango" },
    { "code": "si", "name": "Seylanca" },
    { "code": "sk", "name": "Slovakça" },
    { "code": "sl", "name": "Slovence" },
    { "code": "sm", "name": "Samoa Dili" },
    { "code": "sn", "name": "Shona" },
    { "code": "so", "name": "Somali" },
    { "code": "sq", "name": "Arnavutça" },
    { "code": "sr", "name": "Sırpça" },
    { "code": "ss", "name": "Svazicaca" },
    { "code": "st", "name": "Güney Sotho" },
    { "code": "su", "name": "Sunda Dili" },
    { "code": "sv", "name": "İsveççe" },
    { "code": "sw", "name": "Svahili" },
    { "code": "ta", "name": "Tamilce" },
    { "code": "te", "name": "Telugu" },
    { "code": "tg", "name": "Tacikçe" },
    { "code": "th", "name": "Tayca" },
    { "code": "ti", "name": "Tigrinya" },
    { "code": "tk", "name": "Türkmence" },
    { "code": "tl", "name": "Tagalog" },
    { "code": "tn", "name": "Tswana" },
    { "code": "to", "name": "Tonga Dili" },
    { "code": "tr", "name": "Türkçe" },
    { "code": "ts", "name": "Tsonga" },
    { "code": "tt", "name": "Tatarca" },
    { "code": "tw", "name": "Twi" },
    { "code": "ty", "name": "Tahitian" },
    { "code": "ug", "name": "Uygurca" },
    { "code": "uk", "name": "Ukraynaca" },
    { "code": "ur", "name": "Urduca" },
    { "code": "uz", "name": "Özbekçe" },
    { "code": "ve", "name": "Venda" },
    { "code": "vi", "name": "Vietnamca" },
    { "code": "vo", "name": "Volapük" },
    { "code": "wa", "name": "Valonca" },
    { "code": "wo", "name": "Wolof" },
    { "code": "xh", "name": "Xhosa" },
    { "code": "yi", "name": "Yidiş" },
    { "code": "yo", "name": "Yorubaca" },
    { "code": "za", "name": "Zhuang; Chuang" },
    { "code": "zh", "name": "Çince" },
    { "code": "zu", "name": "Zulu" }
  ]