import { LANGUAGE_TYPE_CHANGED, LANGUAGE_CHANGED } from "../types";
import store from "../index";

const dispatch = store.dispatch;

export const onLanguageChange = (language) => {
  dispatch({ type: LANGUAGE_CHANGED, payload: language });
};
// "en" or "tr"
export const onLanguageTypeChange = (type) => {
  dispatch({ type: LANGUAGE_TYPE_CHANGED, payload: type });
};
