/* eslint-disable react-hooks/exhaustive-deps */
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";

import { useEffect, useState } from "react";
import { getNewAnswerByAnswer } from "./useSurveyHook";

export const JOB_KEY = "dynamic-job";

export default function useDynamicJobHook({
  setSurvey,
  survey,
  initialdetails,
}) {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [answers, setAnswers] = useState([]);

  const jobAnswer = answers.find((item) => item.key === JOB_KEY);
  const jobQuestion = {
    key: JOB_KEY,
    inputType: "multi-choice",
    question: "İş seçiniz",
    placeholder: "Seçiniz",
    sheetTitle: "İş seçiniz",
    choices: jobs?.map((item) => ({
      key: item.key,
      label: item.label ? item.label : item.key,
    })),
  };

  const addOrUpdateSurvey = (newQuestions) => {
    const newSurveys = [...survey];
    const currentPageIndex = 0;
    newSurveys[currentPageIndex] = {
      ...newSurveys[currentPageIndex],
      questions: newQuestions,
    };

    setSurvey(newSurveys);
  };

  const getJobs = async () => {
    setLoading(true);
    const result = await APIEndpointHelper.getDynamicJobs();

    if (result) {
      setJobs(result.data);
    }
    setLoading(false);
  };

  const onSelectAnswer = (questionKey, answer) => {
    const allQuestions = [].concat.apply(
      [],
      survey.map((item) => item.questions)
    );
    const question = [jobQuestion, ...allQuestions].find(
      (item) => item.key === questionKey
    );
    if (!question) {
      return;
    }
    const copyAnswers = [...answers];
    const answerIndex = copyAnswers.findIndex(
      (item) => item.key === questionKey
    );
    const newAnswer = getNewAnswerByAnswer(
      answer,
      question,
      copyAnswers[answerIndex]
    );
    if (answerIndex < 0) {
      copyAnswers.push(newAnswer);
    } else {
      copyAnswers[answerIndex] = newAnswer;
    }

    setAnswers(copyAnswers);
  };

  const convertDynamicQuestionTypeToSurveyQType = (type) => {
    switch (type) {
      case "multiple-choice":
        return "multi-choice";
      case "range":
        return "range";
      case "single-choice":
        return "single-choice";
      case "text":
        return "textbox";
    }
    return "textbox";
  };

  const getNewQuestions = async (jobKeyParameter) => {
    const joined = jobKeyParameter.join(",");
    const response = await APIEndpointHelper.getDynamicJobQuestions(joined);

    if (!response) {
      return;
    }

    const newSurveyQuestions = [
      // {
      //   key: JOB_KEY,
      //   inputType: "single-choice",
      //   question: "İş seçiniz",
      //   placeholder: "Seçiniz",
      //   sheetTitle: "İş seçiniz",
      //   choices: jobs.map((item) => ({
      //     key: item.key,
      //     label: item.label ? item.label : item.key,
      //   })),
      // },
    ];
    const newAnswers = [answers[0]];
    for (let i = 0; i < response.data.length; i++) {
      const qKey = response.data[i].key;
      response.data[i].questions?.forEach((item) => {
        newSurveyQuestions.push({
          inputType: convertDynamicQuestionTypeToSurveyQType(item.type),
          key: `${joined}|${item.key}|${qKey}`,
          question: item.label,
          choices: item.choices?.map((data) => ({
            key: data.key,
            label: data.label || data.key,
          })),
          max: item.max,
          min: item.min,
        });
        
        if (initialdetails.jobs && initialdetails.jobs.length > 0) {
          const foundJob = initialdetails.jobs.find(
            (item) => item.jobKey === qKey
          );
          const jobOptions = foundJob?.jobOptions;
          if (jobOptions) {
            const foundOption = jobOptions.find(
              (data) => data.key === item.key
            );
            if (foundOption) {
              const answer = {
                key: `${jobKeyParameter}|${item.key}|${qKey}`,
                inputType: convertDynamicQuestionTypeToSurveyQType(item.type),
                feedback: foundOption.value,
              };
              newAnswers.push(answer);
            }
          }
        }
      });
    }
    addOrUpdateSurvey(newSurveyQuestions);

    if (newAnswers.length > 1) {
      setAnswers(newAnswers);
    }
  };

  const getAnswerByQuestion = (questionKey) => {
    const answerData = answers.find((item) => item.key === questionKey);
    return answerData ? { ...answerData } : undefined;
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    if (jobs.length > 0) {
      addOrUpdateSurvey([
        // {
        //   key: JOB_KEY,
        //   inputType: "multi-choice",
        //   question: "İş seçiniz",
        //   placeholder: "Seçiniz",
        //   sheetTitle: "İş seçiniz",
        //   choices: jobs.map((item) => ({
        //     key: item.key,
        //     label: item.label ? item.label : item.key,
        //   })),
        // },
      ]);

      if (initialdetails.jobs && initialdetails.jobs.length > 0) {
        const jobAnswer = initialdetails.jobs.map((item) => item.jobKey);
        const answer = {
          key: JOB_KEY,
          inputType: "multi-choice",
          feedback: jobAnswer,
        };

        setAnswers([answer]);
      }
    }
  }, [jobs, jobs.length, initialdetails]);

  useEffect(() => {
    if (jobAnswer?.key) {
      getNewQuestions(jobAnswer?.feedback);
    }
  }, [jobAnswer?.key, jobAnswer?.feedback]);
  return {
    jobQuestion,
    loading,
    answers,
    jobs,
    onSelectAnswer,
    getAnswerByQuestion,
  };
}
