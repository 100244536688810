import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from "../../data/countries"; // JSON dosyanızı içeri aktarın
import { useSelector } from "react-redux";

const CustomPhoneInput = ({ value, onChange }) => {
  const language = useSelector(
    (r_state) => r_state.SettingResponse.language.userProfile
  );
  const customCountries = countries.map((country) => ({
    name: country.name,
    iso2: country.iso2,
    dialCode: country.dialCode,
  }));

  const filterCountries = (searchQuery) => {
    return customCountries.filter((country) =>
      country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <PhoneInput
      value={value}
      onChange={onChange}
      country={"tr"} // Varsayılan ülke kodu
      onlyCountries={customCountries.map((c) => c.iso2.toLowerCase())}
      countryData={customCountries}
      enableSearch={true}
      searchPlaceholder={language.search_country}
      localization={{}} // Buraya dil özelleştirmeleri ekleyebilirsiniz
      filterCountries={filterCountries}
    />
  );
};

export default CustomPhoneInput;
