import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";

import { useSelector } from "react-redux";
import { dangerColor } from "../../assets/jss/material-dashboard-react";
import { useRef } from "react";
import AWSHelper from "helpers/AwsHelper";
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardCategoryDanger: {
    color: dangerColor[0],
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  passportCard: {
    backgroundColor: "rgba(0, 0, 0, .62)",
    borderRadius: 10,
    width: 300,
    height: 200,
    alignSelf: "center",
    objectFit: "contain"
  },
};

const useStyles = makeStyles(styles);

export default function PassportCard({
  account,
  refreshUserInfo,
  userDetails,
}) {
  const language = useSelector(
    (r_state) => r_state.SettingResponse.language.userProfile
  );
  const [uploadFile, setUploadFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userinfo, setUserInfo] = useState(account);
  const fileInputRef = useRef();

  const handleChange = (event) => {
    // do something with event data
    const file = event.target.files[0];
    setUploadFile(file);
  };

  const classes = useStyles();
  const uploadImage = async () => {
    setLoading(true);
    const result = await AWSHelper.uploadFile(uploadFile, uploadFile.name);
    if (!result) {
      setLoading(false);
      return;
    }

    await APIEndpointHelper.updateProviderDetail(account._id, {
      passport: result,
    });
    await refreshUserInfo();
    setLoading(false);
    setUploadFile(null);
  };

  useEffect(() => {
    setUserInfo(account);
  }, [account]);

  const selectedImage = uploadFile ? URL.createObjectURL(uploadFile) : null;
  let avatar = userinfo.image;
  const shownImage = uploadFile
    ? selectedImage
    : userDetails?.passport || avatar;

  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card>
        {shownImage ? (
          <div className={classes.passportCard}>
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                className={classes.passportCard}
                src={shownImage}
                alt="..."
              />
            </a>
          </div>
        ) : null}
        <CardBody profile>
          {!uploadFile ? (
            <Button
              onClick={() => fileInputRef.current.click()}
              color="primary"
              round
            >
              {language.addPassport}
            </Button>
          ) : null}
          {uploadFile ? (
            <>
              <Button
                loading={loading}
                onClick={uploadImage}
                color="info"
                round
              >
                {language.upload}
              </Button>
              <Button onClick={() => setUploadFile(null)} color="danger" round>
                {language.cancel}
              </Button>
            </>
          ) : null}
          <input
            onChange={handleChange}
            multiple={false}
            ref={fileInputRef}
            type="file"
            hidden
          />
          {/* {!noMembership && (
            <Button
              onClick={() => setExtendModal({ modal: true, apiData: null })}
              color="primary"
              round
            >
              {language.extendMembership}
            </Button>
          )} */}
          {/* <Button color="primary" round>
            {language.sendMessage}
          </Button> */}
        </CardBody>
      </Card>
    </GridItem>
  );
}

PassportCard.propTypes = {
  account: PropTypes.any,
  gender: PropTypes.number,
  refreshUserInfo: PropTypes.func,
};
