import { DUMY_JOBS } from "types/dummy";
import ApiHelper from "./ApiHelper";
import { DUMMY_JOB_QUESTION_RESPONSE } from "types/dummy";

class ApiEndpointHelper {
  async login(email, password) {
    const result = await ApiHelper.post("/admin/v1/auth/login", {
      email,
      password,
    });

    if (!result) {
      return;
    }

    return result;
  }

  async getUsers(page = 1) {
    const result = await ApiHelper.get(`/admin/v1/user`);
    if (!result) {
      return;
    }

    return result;
  }

  async getProviders(page = 1) {
    const result = await ApiHelper.get(`/admin/v1/user/provider`);
    if (!result) {
      return;
    }

    return result;
  }

  async getSearchers(page = 1) {
    const result = await ApiHelper.get(`/admin/v1/user/searcher`);
    if (!result) {
      return;
    }

    return result;
  }

  async getReports(page = 1) {
    const result = await ApiHelper.get(`/admin/v1/user/reports`);
    if (!result) {
      return;
    }

    return result;
  }

  async getUserInfo(userId) {
    const result = await ApiHelper.get(`/admin/v1/user/${userId}`);
    if (!result) {
      return;
    }

    return result;
  }

  async updateUser(userId, data) {
    const result = await ApiHelper.put(`/admin/v1/user/${userId}`, {
      user: data,
    });
    if (!result) {
      return;
    }

    return result;
  }

  async approveAccount(userId) {
    const result = await ApiHelper.put(`/admin/v1/user/confirm/${userId}`);
    if (!result) {
      return;
    }

    return result;
  }

  async rejectAccount(userId) {
    const result = await ApiHelper.put(`/admin/v1/user/reject/${userId}`);
    if (!result) {
      return;
    }

    return result;
  }

  async activateAccount(userId) {
    const result = await ApiHelper.put(
      `/admin/v1/user/provider/active/${userId}`
    );
    if (!result) {
      return;
    }

    return result;
  }

  async deactivateAccount(userId) {
    const result = await ApiHelper.put(
      `/admin/v1/user/provider/inactive/${userId}`
    );
    if (!result) {
      return;
    }

    return result;
  }

  async activateSearcher(userId) {
    const result = await ApiHelper.put(
      `/admin/v1/user/searcher/active/${userId}`
    );
    if (!result) {
      return;
    }

    return result;
  }

  async deactivateSearcher(userId) {
    const result = await ApiHelper.put(
      `/admin/v1/user/searcher/inactive/${userId}`
    );
    if (!result) {
      return;
    }

    return result;
  }

  getDynamicJobs = async () => {
    const result = await ApiHelper.get("/admin/v1/job");
    if (!result || !result.success) {
      return DUMY_JOBS;
    }

    return result;
  };

  getDynamicJobQuestions = async (jobKey) => {
    const result = await ApiHelper.get(`/admin/v1/job/${jobKey}`);
    if (!result || !result.success) {
      return DUMMY_JOB_QUESTION_RESPONSE;
    }

    return result;
  };

  updateProviderDetail = async (userId, data) => {
    const result = await ApiHelper.put(
      `/admin/v1/user/provider/${userId}`,
      data
    );
    if (!result) {
      return;
    }

    return result;
  };

  addNewProvider = async (data) => {
    const result = await ApiHelper.post(`/admin/v1/user/provider`, data);
    if (!result) {
      return;
    }

    return result;
  };

  createGdprLink = async (redirecrUri, email, phone) => {
    const result = await ApiHelper.post(`/admin/v1/user/gdprLink`, {
      link: redirecrUri,
      email,
      phone,
    });
    if (!result) {
      return;
    }

    return result;
  };

  async deleteUser(userId) {
    const result = await ApiHelper.delete(
      `/admin/v1/user/${userId}`
    );
    if (!result) {
      return;
    }

    return result;
  }
}

export const APIEndpointHelper = new ApiEndpointHelper();
