import React from "react";

const Display = ({ children, show }) => {
  if (!show) {
    return null;
  }

  return <>{children}</>;
};

export default Display;
