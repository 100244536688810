import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";

export default function TwoTextInputSelect({ question, answer, onSelect }) {
  const selectedFirst = answer?.feedback[0] || "";

  const selectedSecond = answer?.feedback[1] || "";

  const onSelectFirstPicker = (newSelected) => {
    onSelect?.(question.key, `${newSelected}|${selectedSecond || ""}`);
  };

  const onSelectSecondPicker = (newSelected) => {
    onSelect?.(question.key, `${selectedFirst || ""}|${newSelected}`);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <CustomInput
          labelText={question.placeholder1}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: selectedFirst,
            onChange: (e) => onSelectFirstPicker(e.target.value),
          }}
          multiline={question.multiline}
        />
      </GridItem>

      <GridItem xs={12} sm={6} md={6}>
        <CustomInput
          labelText={question.placeholder2}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: selectedSecond,
            onChange: (e) => onSelectSecondPicker(e.target.value),
          }}
          multiline={question.multiline}
        />
      </GridItem>
    </GridContainer>
  );
}
