import React, { useEffect } from "react";
import Snackbar from "components/Snackbar/Snackbar.js";
import { useSelector } from "react-redux";
import { SnackbarHelper } from "helpers";

const second = 6;
let interval;
let timer = 0;

const GenericSnackbar = () => {
  const data = useSelector((r_state) => r_state.ComponentResponse.snackbarData);
  const closeBar = () => {
    SnackbarHelper.closeSnackbar();
    timer = 0;
    if (interval) clearInterval(interval);
  };
  useEffect(() => {
    if (data.open) {
      interval = setInterval(() => {
        if (timer >= second) closeBar();
        timer++;
      }, 1000);
    }
  }, [data]);
  return (
    <Snackbar
      place={data.place || "bc"}
      color={data.color || "info"}
      icon={data.icon}
      message={data.message || ""}
      open={data.open}
      closeNotification={closeBar}
      close
    />
  );
};

export default GenericSnackbar;
