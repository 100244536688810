import { LOADING_DIALOG_CHANGED, SNACKBAR_CHANGED } from "../types";
import store from "../index";

const dispatch = store.dispatch;

export const onChangeSnackbar = (data) => {
  dispatch({ type: SNACKBAR_CHANGED, payload: data });
};

export const showLoadingDialog = () => {
  dispatch({ type: LOADING_DIALOG_CHANGED, payload: true });
};

export const hideLoadingDialog = () => {
  dispatch({ type: LOADING_DIALOG_CHANGED, payload: false });
};
