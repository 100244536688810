import React from "react";
import GridItem from "components/Grid/GridItem";
import Text from "components/Text/Text";
import Display from "components/Display/Display";
import Textbox from "./Textbox";
import Singleselect from "./SingleChoice";
import DateSelect from "./DateSelect";
import RangeSelect from "./RangeSelect";
import MultiSelect from "./MultiSelect";
import GoogleAutoComleteQuestion from "./GoogleAutocompleteQuestion";
import LocationSelect from "./LocationSelect";
import TwoInputSelect from "./TwoInputSelect";
import TwoTextInputSelect from "./TwoTextInputSelect";
import IncrementalQuestion from "./IncrementalQuestion";
import DayMonthYear from "./DayMonthYear";
import Phonebox from "./PhoneBox";

export default function Question({
  question,
  answer,
  onSelect,
  answers,
  ...rest
}) {
  return (
    <GridItem xs={12} sm={12} md={12}>
      <Text style={{ fontWeight: "600", marginTop: 30 }}>
        {question.question}
      </Text>

      <Display show={question.inputType === "multi-choice"}>
        <MultiSelect question={question} onSelect={onSelect} answer={answer} />
      </Display>
      <Display show={question.inputType === "incremental"}>
        <IncrementalQuestion
          question={question}
          onSelect={onSelect}
          answer={answer}
        />
      </Display>
      <Display show={question.inputType === "single-choice"}>
        <Singleselect question={question} onSelect={onSelect} answer={answer} />
      </Display>
      <Display show={question.inputType === "textbox"}>
        <Textbox question={question} onSelect={onSelect} answer={answer} />
      </Display>
      <Display show={question.inputType === "date"}>
        <DateSelect question={question} onSelect={onSelect} answer={answer} />
      </Display>
      <Display show={question.inputType === "range"}>
        <RangeSelect
          answers={answers}
          question={question}
          onSelect={onSelect}
          answer={answer}
        />
      </Display>
      <Display show={question.inputType === "day-month-year"}>
        <DayMonthYear
          answers={answers}
          question={question}
          onSelect={onSelect}
          answer={answer}
        />
      </Display>
      <Display show={question.inputType === "google-autocomplete"}>
        <GoogleAutoComleteQuestion
          question={question}
          onSelect={onSelect}
          answer={answer}
        />
      </Display>
      <Display show={question.inputType === "location"}>
        <LocationSelect
          question={question}
          onSelect={onSelect}
          answer={answer}
        />
      </Display>
      <Display show={question.inputType === "two-input-single-choice"}>
        <TwoInputSelect
          question={question}
          onSelect={onSelect}
          answer={answer}
        />
      </Display>
      <Display show={question.inputType === "two-text-input-single-choice"}>
        <TwoTextInputSelect
          question={question}
          onSelect={onSelect}
          answer={answer}
        />
      </Display>
      <Display show={question.inputType === "phone"}>
        <Phonebox question={question} onSelect={onSelect} answer={answer} />
      </Display>
    </GridItem>
  );
}
