import { onChangeSnackbar } from "store/actions/Component.action";

class SnackbarHelper {
  positions = {
    tl: "tl",
    tc: "tc",
    tr: "tr",
    bl: "bl",
    bc: "bc",
    br: "br",
  };

  colors = {
    info: "info",
    warning: "warning",
    danger: "danger",
    primary: "primary",
    success: "success",
  };

  showSnackbar = (message, color, icon, position) => {
    onChangeSnackbar({
      message,
      color,
      icon,
      place: position,
      open: true,
    });
  };

  closeSnackbar = () => {
    onChangeSnackbar({
      open: false,
    });
  };
}

const SnackBar = new SnackbarHelper();

export default SnackBar;
