import React from "react";
import CustomPhoneInput from "components/CustomPhoneInput/CustomPhoneInput";

export default function Phonebox({ question, answer, onSelect }) {
  const value = answer?.feedback[0];
  const countryCodeValue = answer?.feedback[1] || undefined;
  console.log("My Answer: ", answer);
  const onChangeText = (phone, countryCode) => {
    const code = countryCode.dialCode;
 
    onSelect?.(question.key, `${phone}|${code}`);
  };

  return (
    <CustomPhoneInput
      textInputProps={{
        label: question.sheetTitle,
        maskFormat: "(XXX) XXX XX XX XXX",

        inputProps: {
          // value,
          //   onChangeText: onChangeText,
          multiline: question.multiline,
        },
      }}
      value={`${countryCodeValue}${value}`}
      countryCode={countryCodeValue}
      onChange={onChangeText}
    />
  );
}
