import React from "react";

const Maps = ({ height, onSelectLocation }) => {
  const mapRef = React.useRef(null);
  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let lat = "40.748817";
    let lng = "-73.985428";
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 12,
      center: myLatlng,
      scrollwheel: true,
      zoomControl: true,
      styles: [
        {
          featureType: "water",
          stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            { hue: "#ff0000" },
            { saturation: -100 },
            { lightness: 99 },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#808080" }, { lightness: 54 }],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [{ color: "#ece2d9" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#ccdca1" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#767676" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ffffff" }],
        },
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
        },
        { featureType: "poi.park", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.sports_complex",
          stylers: [{ visibility: "on" }],
        },
        { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "simplified" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    // const marker = new google.maps.Marker({
    //   position: myLatlng,
    //   map: map,
    //   animation: google.maps.Animation.DROP,
    //   title: "Material Dashboard React!",
    // });

    // const contentString =
    //   '<div class="info-window-content"><h2>Material Dashboard React</h2>' +
    //   "<p>A premium Admin for React, Material-UI, and React Hooks.</p></div>";

    // const infowindow = new google.maps.InfoWindow({
    //   content: contentString,
    // });

    // google.maps.event.addListener(marker, "click", function () {
    //   infowindow.open(map, marker);
    // });
    google.maps.event.addListener(map, "dragend", (e) => {
      const center = map.getCenter();
      const lat = center.lat();
      const lng = center.lng();
      onSelectLocation(lat, lng);
      // getAddressFromLatLong(lat, lng).then((res) => {
      //   console.log(res);
      // });
    });
  });
  return (
    <>
      <div style={{ height: height || 300 }} ref={mapRef}></div>
    </>
  );
};

export default Maps;
