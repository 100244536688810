import React, { useEffect } from "react";
import SessionHelper from "helpers/SessionHelper";
import { LanguageHelper } from "helpers";
import { useHistory } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "routes.js";
// import AnalyticHelper from "helpers/AnalyticHelper";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/landing") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/" to="/landing/login" />
  </Switch>
);
/**
 * Initial page of the application
 * Determine a user is logged in
 * After that redirect to a required pages
 * @param {any} props props of the component
 */
const Landing = () => {
  const history = useHistory();
  useEffect(() => {
    const isLoggedIn = SessionHelper.getIsLoggedIn();
    LanguageHelper.init();
    const pushKey = isLoggedIn ? "/admin" : "/landing/login";
    history.push(pushKey);
  }, [history]);

  return <div>{switchRoutes}</div>;
};

export default Landing;
