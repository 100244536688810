import React, { useEffect, useState } from "react";
import Picker from "components/CustomPicker/CustomPicker";
import Question from "./Question";
import { getNewAnswerByAnswer } from "../useSurveyHook";

export default function Singleselect({ question, answer, onSelect }) {
  const [value, setValue] = useState("");
  const selectorData =
    question.choices?.map((item) => ({ value: item.key, label: item.label })) ||
    [];

  const extraAnswers = answer?.feedback[1]
    ? JSON.parse(answer.feedback[1])
    : [];

  const selecteds =
    answer?.feedback.map((item) => ({
      value: item,
      label:
        question.choices?.find((choice) => choice.key === item)?.label || "",
    })) || [];

  const getAnswerByQuestion = (questionKey) => {
    const answerData = extraAnswers.find((item) => item.key === questionKey);
    return answerData ? { ...answerData } : undefined;
  };

  const onSelectAnswerSub = (questionKey, answerSub) => {
    if (!question.extraQuestiions) {
      return;
    }
    const allQuestions = [...question.extraQuestiions];
    const foundQuestion = allQuestions.find((item) => item.key === questionKey);
    if (!foundQuestion) {
      return;
    }
    const copyAnswers = [...extraAnswers];
    const answerIndex = copyAnswers.findIndex(
      (item) => item.key === questionKey
    );
    const newAnswer = getNewAnswerByAnswer(
      answerSub,
      foundQuestion,
      copyAnswers[answerIndex]
    );
    if (answerIndex < 0) {
      copyAnswers.push(newAnswer);
    } else {
      copyAnswers[answerIndex] = newAnswer;
    }
    const answerRes = answer?.feedback[0]
    console.log(copyAnswers);
    onSelect?.(question.key, `${answerRes}|${JSON.stringify(copyAnswers)}`);
  };

  const onSelectPicker = (newSelected) => {
    onSelect?.(question.key, newSelected);
  };

  useEffect(() => {
    if (selecteds[0]) {
      setValue(selecteds[0].value);
    }
  }, [selecteds[0]]);

  const extraValue =
    question.extraQuestionKey &&
    answer?.feedback[0] == question.extraQuestionKey;
  return (
    <>
      <Picker
        label={question.sheetTitle}
        id={question.key}
        data={selectorData}
        value={value}
        onChange={(e) => onSelectPicker(e.target.value)}
      />
      {extraValue ? <div style={{ height: 20 }} /> : null}
      {extraValue &&
        question.extraQuestiions?.map((q) => (
          <Question
            key={q.key}
            question={q}
            answer={getAnswerByQuestion(q.key)}
            onSelect={onSelectAnswerSub}
          />
        ))}
    </>
  );
}
