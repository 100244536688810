import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/material-dashboard-react";
import { grayColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    "&:before": {
      borderColor: grayColor[4],
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
  },
  label: {
    "&:before": {
      color: grayColor[4],
    },
    "&:after": {
      color: primaryColor[0],
    },
  },
  menuItem: {
    backgrounColor: primaryColor[4],
    // "&:after": {
    //   color: primaryColor[0],
    // },
  },
}));

export default function CustomPicker({
  label,
  value,
  onChange,
  data,
  multiple,
}) {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.label} id="demo-simple-select-label">
        {label}
      </InputLabel>
      <Select
        fullWidth
        className={classes.select}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        defaultValue={value}
        onChange={onChange}
        multiple={multiple}
        displayEmpty
      >
        {data.map((item) => (
          <MenuItem
            className={{ selected: classes.menuItem }}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CustomPicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  data: { value: PropTypes.any, label: PropTypes.string },
};

CustomPicker.defaultProps = {
  fullWidth: true,
};
