import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import trLocale from "date-fns/locale/tr";
import format from "date-fns/format";
import { useSelector } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import PropTypes from "prop-types";

class TrLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "LLLL yyyy", { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, "dd MMMM", { locale: this.locale });
  }
}

const localeMap = {
  en: enLocale,
  tr: trLocale,
};

const localeUtilsMap = {
  en: DateFnsUtils,
  tr: TrLocalizedUtils,
};

const localeFormatMap = {
  en: "MM/dd/yyyy",
  tr: "dd/MM/yyyy",
};

const localeCancelLabelMap = {
  en: "cancel",
  tr: "iptal",
};

export default function CustomDatePicker({
  label,
  value,
  onChangeText,
  fullWidth,
  minDate,
  disabled,
}) {
  const locale = useSelector((r_state) => r_state.SettingResponse.languageType);
  return (
    <MuiPickersUtilsProvider
      utils={localeUtilsMap[locale]}
      locale={localeMap[locale]}
    >
      <KeyboardDatePicker
        fullWidth={fullWidth}
        disableToolbar
        variant="inline"
        format={localeFormatMap[locale]}
        margin="normal"
        id="date-picker-inline"
        label={label}
        value={value}
        onChange={onChangeText}
        cancelLabel={localeCancelLabelMap[locale]}
        showTodayButton
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        minDate={minDate}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
}

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  value: Date,
  onChangeText: PropTypes.func,
  error: PropTypes.bool,
  success: PropTypes.bool,
  rtlActive: PropTypes.bool,
  fullWidth: PropTypes.bool,
  minDate: Date,
  disabled: PropTypes.bool,
};

CustomDatePicker.defaultProps = {
  fullWidth: true,
  disabled: false,
};
