/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "store";
import Snackbar from "components/GenericSnackbar/GenericSnackbar";
import LoadingDialog from "components/LoadingDialog/LoadingDialog";

// core components
import Admin from "layouts/Admin.js";
// import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import Landing from "layouts/Landing";
import { primaryColor } from "assets/jss/material-dashboard-react";

const customTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#42c2f5",
      main: primaryColor[0],
      dark: "#778899",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiMenuItem: {
      // For ListItem, change this to MuiListItem
      root: {
        "&$selected": {
          // this is to refer to the prop provided by M-UI
          backgroundColor: primaryColor[3], // updated backgroundColor
        },
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={customTheme}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/landing" component={Landing} />
          {/* <Route path="/rtl" component={RTL} /> */}
          <Redirect from="/" to="/landing/" />
        </Switch>
      </BrowserRouter>
      <Snackbar />
      <LoadingDialog />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
