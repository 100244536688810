import React from "react";
import store from "store";
import moment from "moment";
import SnackBar from "../../helpers/SnackbarHelper";
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";

export const getAllMembers = async () => {
  // const { data } = await getAllMembersFromService();
  const data = await APIEndpointHelper.getProviders();
  const language = store.getState().SettingResponse.language.members;
  const shownProperties = [
    "_id",
    "name",
    "email",
    "firebaseID",
    "password",
    "createdAt",
    "gdpr",
    "status",
  ];
  const rows = [];
  console.log(data);
  if (!data.data) {
    return rows;
  }
  data.data.providers.forEach((item) => {
    const row = [];
    shownProperties.forEach((property) => {
      let addedProp = item[property];
      if (property === "email") {
        addedProp = item.user?.email;
      }
   
      if (property === "password") {
        addedProp = item.user?.password;
      }
      if (property === "firebaseID") {
        addedProp = item.user?.uid;
      }
      if (property === "name") {
        addedProp = item.user?.name;
      }
      if (property === "createdAt") {
        addedProp = item.user?.createdAt;
      }

      if (!addedProp && property === "createdAt") {
        addedProp = <p style={{ color: "red" }}>{"Tarih Yok"}</p>;
      } else if (property === "createdAt") {
        addedProp = moment(addedProp).format("Do MMMM YY");
      }

      if (property === "gdpr") {
        addedProp = (
          <p style={{ color: addedProp ? "green" : "red" }}>
            {addedProp ? "Onayladı" : "Onaylamadı"}
          </p>
        );
      }

      if (property === "status") {
        addedProp = (
          <p style={{ color: addedProp === "active" ? "green" : "red" }}>
            {addedProp === "active" ? "Aktif" : "Pasif"}
          </p>
        );
      }

      row.push(addedProp);
    });
    row.push(item);
    rows.push(row);
  });
  return rows;
};

export const getHeaderTitlesByLanguage = (language) => {
  return [
    language.id,
    language.name,
    language.email,
    "Firebase ID",
    language.password,
    language.created_at,
    language.gdpr_status,
    language.status,
  ];
};

export const createAccount = async (data) => {
  const languageType = store.getState().SettingResponse.languageType;
  const response = await APIEndpointHelper.addNewProvider(data);
  if (response.success) {
    SnackBar.showSnackbar(
      response.Message[languageType] || "İşlem Başarılı",
      SnackBar.colors.success
    );
    return true;
  }
  if (response.Status === 4 || response.Status === 3) {
    SnackBar.showSnackbar(
      response.Message[languageType],
      SnackBar.colors.danger
    );
    return false;
  }
};
