import React from "react";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";

export default function DateSelect({ question, answer, onSelect }) {
  const addZero = (num) => {
    if (num < 10) {
      return `0${num}`;
    }
    return `${num}`;
  };
  const onSelectPicker = (newSelected) => {
    console.log(
      `${newSelected.getFullYear()}-${addZero(
        newSelected.getMonth() + 1
      )}-${addZero(newSelected.getDate())}`
    );
    onSelect?.(
      question.key,
      `${newSelected.getFullYear()}-${addZero(
        newSelected.getMonth() + 1
      )}-${addZero(newSelected.getDate())}`
    );
  };

  return (
    <CustomDatePicker
      id={question.key}
      label={question.sheetTitle}
      value={answer?.feedback[0] ? new Date(answer.feedback[0]) : null}
      onChangeText={(e) => onSelectPicker(e)}
    />
  );
}
