import { parsePhoneNumberFromString } from "libphonenumber-js";

const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return { isValid: false, message: "Telefon numarası boş olamaz." };
  }

  const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);

  if (!phoneNumberObject) {
    return { isValid: false, message: "Geçersiz telefon numarası." };
  }

  if (!phoneNumberObject.isValid()) {
    return { isValid: false, message: "Geçersiz telefon numarası." };
  }

  return { isValid: true };
};

export default validatePhoneNumber;
