import { useSelector } from "react-redux";
import { nationalities } from "views/Survey/nationalities";
import { languages } from "views/Survey/languages";

// İŞ ARIYORUM
export default function useProviderSurveyHook() {
  const language = useSelector(
    (r_state) => r_state.SettingResponse.language.survey
  );
  const i18n = {
    t: (key, data) => {
      const postFix = key.split(".")[1];
      return language[postFix];
    },
  };
  const nationalityOptions = nationalities.map((item) => ({
    key: item,
    label: item,
  }));

  const languageOptions = languages
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    .map((item) => ({ key: item.code, label: item.name }));
  const SURVEY_PAGE_1_QUESTIONS = [
    {
      key: "phone",
      inputType: "phone",
      keyboardType: "number-pad",
      question: i18n.t("survey.contact_info"),
      sheetTitle: i18n.t("survey.phone_number"),
      placeholder: i18n.t("survey.phone_number"),
    },
    // {
    //   key: 'workType',
    //   inputType: 'single-choice',
    //   question: i18n.t('survey.work_type'),
    //   sheetTitle: i18n.t('survey.select_work_type'),
    //   placeholder: i18n.t('survey.select'),
    //   choices: [
    //     {key: 'daily', label: i18n.t('survey.daily')},
    //     {key: 'overnight', label: i18n.t('survey.overnight')},
    //   ],
    // },
    {
      key: "gender",
      inputType: "single-choice",
      question: i18n.t("survey.gender"),
      sheetTitle: i18n.t("survey.select_gender"),
      placeholder: i18n.t("survey.select"),
      choices: [
        { key: "m", label: i18n.t("survey.male") },
        { key: "f", label: i18n.t("survey.female") },
      ],
    },
    {
      key: "birthDate",
      inputType: "date",
      question: i18n.t("survey.birth_date"),
      placeholder: i18n.t("survey.select"),
    },

    // {
    //   key: 'nationality',
    //   inputType: 'textbox',
    //   question: i18n.t('survey.nationality'),
    //   placeholder: i18n.t('survey.enter_nationality'),
    //   defaultValue: '',
    // },
    {
      key: "nationality",
      inputType: "single-choice",
      question: i18n.t("survey.nationality"),
      sheetTitle: i18n.t("survey.enter_nationality"),
      placeholder: i18n.t("survey.enter_nationality"),
      choices: nationalityOptions,
      // [
      //   { key: "turkish", label: i18n.t("survey.turkish_citizen") },
      //   { key: "turkoman", label: i18n.t("survey.turkoman") },
      //   { key: "uzbek", label: i18n.t("survey.uzbek") },
      //   { key: "georgian", label: i18n.t("survey.georgian") },
      //   { key: "moldova", label: i18n.t("survey.moldova") },
      //   { key: "african", label: i18n.t("survey.african") },
      //   { key: "indonesian", label: i18n.t("survey.indonesian") },
      //   { key: "filipino", label: i18n.t("survey.filipino") },
      //   { key: "other", label: i18n.t("survey.other") },
      // ],
    },
    // {
    //   key: "birthPlace",
    //   inputType: "google-autocomplete",
    //   question: i18n.t("survey.birth_place"),
    //   sheetTitle: i18n.t("survey.find_location"),
    //   locationButtonText: i18n.t("survey.ok"),
    // },
    {
      key: "language",
      inputType: "multi-choice",
      question: i18n.t("survey.language"),
      sheetTitle: i18n.t("survey.select_language"),
      placeholder: i18n.t("survey.select"),
      choices: languageOptions,
      // [
      //   { key: "turkish", label: i18n.t("survey.turkish") },
      //   { key: "english", label: i18n.t("survey.english") },
      //   { key: "russian", label: i18n.t("survey.russian") },
      //   { key: "arabic", label: i18n.t("survey.arabic") },
      //   { key: "france", label: i18n.t("survey.france") },
      //   { key: "german", label: i18n.t("survey.german") },
      // ],
    },
    {
      key: "maritalStatus",
      inputType: "single-choice",
      question: i18n.t("survey.martial_status"),
      choices: [
        { key: "married", label: i18n.t("survey.married") },
        { key: "single", label: i18n.t("survey.single") },
        { key: "widow", label: i18n.t("survey.widow") },
        { key: "divorced", label: i18n.t("survey.divorced") },
        { key: "engaged", label: i18n.t("survey.engaged") },
      ],
    },
    {
      key: "pets",
      inputType: "multi-choice",
      question: i18n.t("survey.pets"),
      sheetTitle: i18n.t("survey.select_pet"),
      placeholder: i18n.t("survey.select"),
      choices: [
        { key: "cat", label: i18n.t("survey.cat") },
        { key: "dog", label: i18n.t("survey.dog") },
        { key: "bird", label: i18n.t("survey.bird") },
        { key: "other", label: i18n.t("survey.other") },
      ],
    },
    {
      key: "familyMembers",
      inputType: "incremental",
      question: i18n.t("survey.family_count"),
      choices: [
        { key: "adult", label: i18n.t("survey.adult") },
        { key: "kid", label: i18n.t("survey.kid") },
        { key: "personnel", label: i18n.t("survey.personnel") },
      ],
      defaultValue: ["adult|0", "kid|0", "personnel|0"],
    },
    {
      key: "residencePermit",
      inputType: "single-choice",
      question: i18n.t("survey.residence_permit"),
      sheetTitle: i18n.t("survey.select"),
      placeholder: i18n.t("survey.select"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes2") },
        { key: "no", label: i18n.t("survey.no2") },
        { key: "in_progress", label: i18n.t("survey.apply_progress") },
        // {key: 'in_review', label: i18n.t('survey.in_review')},
      ],
    },
    {
      key: "workPermit",
      inputType: "single-choice",
      question: i18n.t("survey.work_permit"),
      sheetTitle: i18n.t("survey.select"),
      placeholder: i18n.t("survey.select"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes2") },
        { key: "no", label: i18n.t("survey.no2") },
        { key: "retired", label: i18n.t("survey.retired") },
        { key: "tc", label: i18n.t("survey.tc") },
        // {key: 'in_review', label: i18n.t('survey.in_review')},
      ],
    },
    {
      key: "education_type",
      inputType: "single-choice",
      question: i18n.t("survey.education"),
      sheetTitle: i18n.t("survey.select"),
      placeholder: i18n.t("survey.select"),
      choices: [
        { key: "university", label: i18n.t("survey.university") },
        { key: "highschool", label: i18n.t("survey.highschool") },
        { key: "elemantary", label: i18n.t("survey.elemantary") },
        { key: "student", label: i18n.t("survey.student") },
        { key: "other", label: i18n.t("survey.other") },
        // {key: 'in_review', label: i18n.t('survey.in_review')},
      ],
    },
    // {
    //   key: "education_department",
    //   inputType: "textbox",
    //   question: i18n.t("survey.department"),
    //   placeholder: i18n.t("survey.enter_department"),
    // },
  ];

  const SURVEY_PAGE_2_QUESTIONS = [
    {
      key: "location",
      inputType: "google-autocomplete",
      question: i18n.t("survey.address"),
      sheetTitle: i18n.t("survey.find_location"),
      locationButtonText: i18n.t("survey.ok"),
    },
    {
      key: "distance",
      inputType: "textbox",
      question: i18n.t("survey.distance"),
      sheetTitle: i18n.t("survey.find_location"),
      placeholder: i18n.t("survey.enter_distance"),
      max: 100,
      defaultValue: "1",
      rangePostfix: " km",
    },
    {
      key: "postalCode",
      inputType: "textbox",
      question: i18n.t("survey.postal_code"),
      placeholder: i18n.t("survey.enter_postal_code"),
      keyboardType: "number-pad",
    },
  ];

  const SURVEY_PAGE_4_QUESTIONS = [
    {
      key: "howManyYearsInCurrentCountry",
      inputType: "day-month-year",
      question: i18n.t("survey.how_many_year"),
      placeholder: i18n.t("survey.enter_year"),
      keyboardType: "number-pad",
    },
    {
      key: "total_experience",
      inputType: "two-text-input-single-choice",
      question: i18n.t("survey.total_experience"),
      sheetTitle: i18n.t("survey.select"),
      placeholder1: i18n.t("survey.year_title"),
      placeholder2: i18n.t("survey.month_title"),
      choices: [
        { key: "1", label: "1 Yıl" },
        { key: "2", label: "2 Yıl" },
        { key: "3", label: "3 Yıl" },
        { key: "4", label: "4 Yıl" },
        { key: "5", label: "5 Yıl" },
        { key: "6", label: "6 Yıl" },
        { key: "7", label: "7 Yıl" },
        { key: "8", label: "8 Yıl" },
        { key: "9", label: "9 Yıl" },
        { key: "10", label: "10+ Yıl" },
      ],
      secondChoices: [
        { key: "1", label: "1 Ay" },
        { key: "2", label: "2 Ay" },
        { key: "3", label: "3 Ay" },
        { key: "4", label: "4 Ay" },
        { key: "5", label: "5 Ay" },
        { key: "6", label: "6 Ay" },
        { key: "7", label: "7 Ay" },
        { key: "8", label: "8 Ay" },
        { key: "9", label: "9 Ay" },
        { key: "10", label: "10 Ay" },
        { key: "11", label: "11 Ay" },
      ],
    },
    {
      key: "reference",
      inputType: "single-choice",
      question: i18n.t("survey.you_have_reference"),
      placeholder: i18n.t("survey.you_have_reference_placeholder"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes") },
        { key: "no", label: i18n.t("survey.no") },
      ],
    },
    {
      key: "likePet",
      inputType: "single-choice",
      question: i18n.t("survey.like_pet_placeholder"),
      placeholder: i18n.t("survey.like_pet_placeholder"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes") },
        { key: "no", label: i18n.t("survey.no") },
      ],
    },
    {
      key: "smoking",
      inputType: "single-choice",
      question: i18n.t("survey.smoking_placeholder"),
      placeholder: i18n.t("survey.smoking_placeholder"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes") },
        { key: "no", label: i18n.t("survey.no") },
      ],
    },
    {
      key: "knowSwimming",
      inputType: "single-choice",
      question: i18n.t("survey.swimming_placeholder"),
      placeholder: i18n.t("survey.swimming_placeholder"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes") },
        { key: "no", label: i18n.t("survey.no") },
      ],
    },
    {
      key: "drivingLicence",
      inputType: "single-choice",
      question: i18n.t("survey.driving_licience_placeholder"),
      placeholder: i18n.t("survey.driving_licience_placeholder"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes") },
        { key: "no", label: i18n.t("survey.no") },
      ],
    },
    {
      key: "hadCovid",
      inputType: "single-choice",
      question: i18n.t("survey.covid_placeholder"),
      placeholder: i18n.t("survey.covid_placeholder"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes") },
        { key: "no", label: i18n.t("survey.no") },
      ],
    },
    {
      key: "vaccinated",
      inputType: "single-choice",
      question: i18n.t("survey.vaccinated_placeholder"),
      placeholder: i18n.t("survey.vaccinated_placeholder"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes") },
        { key: "no", label: i18n.t("survey.no") },
      ],
    },
    {
      key: "willingToGoVacationWithFamily",
      inputType: "single-choice",
      question: i18n.t("survey.go_vacation_family_placeholder"),
      placeholder: i18n.t("survey.go_vacation_family_placeholder"),
      choices: [
        { key: "yes", label: i18n.t("survey.yes") },
        { key: "no", label: i18n.t("survey.no") },
      ],
      extraQuestionKey: "yes",
      extraQuestiions: [
        {
          key: "whichMonths",
          inputType: "multi-choice",
          question: i18n.t("survey.which_month"),
          sheetTitle: i18n.t("survey.which_month"),
          placeholder: i18n.t("survey.select"),
          choices: [
            { key: "0", label: i18n.t("survey.january") },
            { key: "1", label: i18n.t("survey.february") },
            { key: "2", label: i18n.t("survey.march") },
            { key: "3", label: i18n.t("survey.april") },
            { key: "4", label: i18n.t("survey.may") },
            { key: "5", label: i18n.t("survey.june") },
            { key: "6", label: i18n.t("survey.july") },
            { key: "7", label: i18n.t("survey.agust") },
            { key: "8", label: i18n.t("survey.september") },
            { key: "9", label: i18n.t("survey.october") },
            { key: "10", label: i18n.t("survey.november") },
            { key: "11", label: i18n.t("survey.december") },
          ],
        },
        {
          key: "howLong",
          inputType: "two-text-input-single-choice",
          question: i18n.t("survey.how_long"),
          sheetTitle: i18n.t("survey.how_long"),
          placeholder1: i18n.t("survey.day"),
          placeholder2: i18n.t("survey.month1"),
          choices: [
            { key: "0", label: "0" },
            { key: "1", label: "1 Gün" },
            { key: "2", label: "2 Gün" },
            { key: "3", label: "3 Gün" },
            { key: "4", label: "4 Gün" },
            { key: "5", label: "5 Gün" },
            { key: "6", label: "6 Gün" },
            { key: "7", label: "7 Gün" },
            { key: "10", label: "10 Gün" },
            { key: "14", label: "14 Gün" },
            { key: "30", label: "30 Gün" },
            // { key: "11", label: "11 Gün" },
          ],
          secondChoices: [
            { key: "0", label: "0" },
            { key: "1", label: "1 Ay" },
            { key: "2", label: "2 Ay" },
            { key: "3", label: "3 Ay" },
            { key: "4", label: "4 Ay" },
            { key: "5", label: "5 Ay" },
            { key: "6", label: "6 Ay" },
            { key: "7", label: "7 Ay" },
            { key: "8", label: "8 Ay" },
            { key: "9", label: "9 Ay" },
            { key: "10", label: "10 Ay" },
            { key: "11", label: "11 Ay" },
          ],
        },
      ],
    },
    {
      key: "workType",
      inputType: "multi-choice",
      question: i18n.t("survey.work_type"),
      placeholder: i18n.t("survey.select_work_type"),
      choices: [
        { key: "parttime", label: i18n.t("survey.parttime") },
        { key: "daily", label: i18n.t("survey.daily") },
        { key: "overnight", label: i18n.t("survey.overnight") },
        { key: "daytime", label: i18n.t("survey.daytime") },
        { key: "boarding", label: i18n.t("survey.boarding") },
        { key: "all", label: i18n.t("survey.all1") },
      ],
    },
    {
      key: "weekDays",
      inputType: "single-choice",
      question: i18n.t("survey.how_long_work_in_week"),
      // placeholder: i18n.t("survey.select_work_type"),
      choices: [
        { key: "1", label: "1" },
        { key: "2", label: "2" },
        { key: "3", label: "3" },
        { key: "4", label: "4" },
        { key: "5", label: "5" },
        { key: "6", label: "6" },
        { key: "7", label: "7" },
        { key: "dontMatter", label: i18n.t("survey.dont_matter") },
      ],
    },
    {
      key: "houseType",
      inputType: "multi-choice",
      question: i18n.t("survey.house_type"),
      sheetTitle: i18n.t("survey.select_house_type"),
      placeholder: i18n.t("survey.select"),
      choices: [
        { key: "apartment", label: i18n.t("survey.apartment") },
        { key: "duplex", label: i18n.t("survey.dublex") },
        { key: "triplex", label: i18n.t("survey.triplex") },
        { key: "villa", label: i18n.t("survey.villa") },
        { key: "all", label: i18n.t("survey.all1") },
      ],
    },
  ];

  const SURVEY_PAGE_5_QUESTIONS = [
    {
      key: "currency",
      inputType: "single-choice",
      question: i18n.t("survey.currency"),
      sheetTitle: i18n.t("survey.currency_select"),
      placeholder: i18n.t("survey.select"),
      choices: [
        { key: "USD", label: "USD" },
        { key: "TRY", label: "TRY" },
        { key: "EUR", label: "EUR" },
      ],
    },
    {
      key: "salary",
      inputType: "range",
      question: i18n.t("survey.salary_range"),
      min: 10000,
      max: 20000,
    },
    {
      key: "whenToStart",
      inputType: "multi-choice",
      question: i18n.t("survey.when_start"),
      sheetTitle: i18n.t("survey.select_when_start"),
      placeholder: i18n.t("survey.select"),
      choices: [
        { key: "rightAway", label: i18n.t("survey.right_away") },
        { key: "inOneWeek", label: i18n.t("survey.in_one_week") },
      ],
    },
    {
      key: "note",
      inputType: "textbox",
      question: i18n.t("survey.note"),
      placeholder: i18n.t("survey.enter_note"),
      multiline: true,
    },
  ];

  const SURVEY_PAGES = [
    {
      title: i18n.t("survey.general_information"),
      step: 1,
      questions: SURVEY_PAGE_1_QUESTIONS,
    },
    // {
    //   title: i18n.t('survey.location'),
    //   step: 3,
    //   questions: [],
    //   dynamic_type: 'job',
    // },
    {
      title: i18n.t("survey.location"),
      step: 2,
      questions: SURVEY_PAGE_2_QUESTIONS,
    },
    // {
    //   title: i18n.t('survey.location'),
    //   step: 3,
    //   questions: [],
    //   dynamic_type: 'job',
    // },
    {
      title: i18n.t("survey.process_detail"),
      step: 3,
      questions: SURVEY_PAGE_4_QUESTIONS,
    },
    {
      title: i18n.t("survey.salary_info"),
      step: 4,
      questions: SURVEY_PAGE_5_QUESTIONS,
    },
  ];

  return { SURVEY_PAGES };
}

export type SurveyQuestionType =
  | "textbox"
  | "multi-choice"
  | "single-choice"
  | "five-star"
  | "location"
  | "range"
  | "phone"
  | "slider"
  | "incremental"
  | "switch"
  | "switch-extra-question"
  | "date"
  | "radio-select"
  | "hour-select"
  | "google-autocomplete"
  | "two-input-single-choice";

export type DynamicQuestionType =
  | "multipleChoice"
  | "range"
  | "singleChoice"
  | "text";

export interface SurveyQuestion {
  key: string;
  question: string;
  inputType: SurveyQuestionType;
  choices?: { key: string, label: string }[];
  placeholder?: string;
  sheetTitle?: string;
  keyboardType?: string;
  multiline?: boolean;
  locationButtonText?: string;
  min?: number;
  max?: number;
  textMask?: string;
  isGetMasked?: boolean;
  defaultValue?: string | string[];
  extraQuestiions?: SurveyQuestion[];
  extraQuestionKey?: string;
  rangePostfix?: string;
  secondChoices?: { key: string, label: string }[];
}

export interface SurveyPage {
  title: string;
  step: number; // Start from 1
  questions: SurveyQuestion[];
  dynamic_type?: "none" | "job";
}
