import React, { useEffect, useState } from "react";
import AccountComponent from "./AccountComponent";
import ProfileCard from "./ProfileCard";
import { useLocation } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer.js";
import { getUserInfo } from "./helper";
import PassportCard from "./PassportCard";

export default function UserProfile() {
  const location = useLocation();
  const [userinfo, setUserInfo] = useState(location.state);
  const [userDetails, setUserDetails] = useState();

  const setUser = async () => {
    const userData = await getUserInfo(userinfo._id);
    if (userData) setUserInfo(userData.user);
    if (userData?.details) setUserDetails(userData.details);
  };

  const refreshUserInfo = () => {
    setUser();
  };

  useEffect(() => {
    refreshUserInfo();
  }, []);

  return (
    <div>
      <GridContainer>
        <AccountComponent
          refresh={setUser}
          account={userinfo}
          details={userDetails}
        />
        <ProfileCard
          account={userinfo}
          refreshUserInfo={setUser}
          userDetails={userDetails}
        />
        {userinfo.role === "provider" ? (
          <PassportCard
            account={userinfo}
            refreshUserInfo={setUser}
            userDetails={userDetails}
          />
        ) : null}
      </GridContainer>
    </div>
  );
}
