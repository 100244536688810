import { LANGUAGE_CHANGED, LANGUAGE_TYPE_CHANGED } from "../types";
import Language from "assets/languages";

const INITIAL_STATE = {
  language: Language.tr,
  languageType: "tr",
  user: null,
  token: null,
  templates: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LANGUAGE_CHANGED:
      return {
        ...state,
        language: action.payload,
      };
    case LANGUAGE_TYPE_CHANGED:
      return { ...state, fetching: action.payload };
    default:
      return state;
  }
};
