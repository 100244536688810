export const nationalities = [
  "Türk",
  "Özbek",
  "Azeri",
  "Türkmen",
  "Rus",
  "Afgan",
  "Arnavut",
  "Cezayirli",
  "Amerikalı",
  "Andorralı",
  "Angolalı",
  "Antigualı",
  "Arjantinli",
  "Ermeni",
  "Avustralyalı",
  "Avusturyalı",
  "Bahamalı",
  "Bahreynli",
  "Bangladeşli",
  "Barbadolu",
  "Barbudalı",
  "Botsvanalı",
  "Belaruslu",
  "Belçikalı",
  "Belize'li",
  "Beninli",
  "Bhutanlı",
  "Bolivyalı",
  "Bosnalı",
  "Brezilyalı",
  "Britanyalı",
  "Bruneili",
  "Bulgar",
  "Burkina Fasolu",
  "Burmalı",
  "Burundili",
  "Kamboçyalı",
  "Kamerunlu",
  "Kanadalı",
  "Yeşil Burun Adalı",
  "Orta Afrikalı",
  "Çadlı",
  "Şilili",
  "Çinli",
  "Kolombiyalı",
  "Komorlu",
  "Kongolu",
  "Kosta Rikalı",
  "Hırvat",
  "Kübalı",
  "Kıbrıslı",
  "Çek",
  "Danimarkalı",
  "Cibutili",
  "Dominikli",
  "Hollandalı",
  "Doğu Timorlu",
  "Ekvadorlu",
  "Mısırlı",
  "Birleşik Arap Emirlikli",
  "Ekvator Ginesi",
  "Eritreli",
  "Estonyalı",
  "Etiyopyalı",
  "Fijili",
  "Filipinli",
  "Finli",
  "Fransız",
  "Gabonlu",
  "Gambiyalı",
  "Gürcü",
  "Alman",
  "Ganalı",
  "Yunan",
  "Grenadalı",
  "Guatemalalı",
  "Gine-Bissaulu",
  "Gineli",
  "Guyanalı",
  "Haitili",
  "Hersekli",
  "Honduraslı",
  "Macar",
  "Kiribatili",
  "İzlandalı",
  "Hintli",
  "Endonezyalı",
  "İranlı",
  "Iraklı",
  "İrlandalı",
  "İsrailli",
  "İtalyan",
  "Fildişi Sahilliler",
  "Jamaikalı",
  "Japon",
  "Ürdünlü",
  "Kazakistanlı",
  "Kenyalı",
  "Saint Kitts ve Nevisli",
  "Kuveytli",
  "Kırgız",
  "Laoslu",
  "Letonyalı",
  "Lübnanlı",
  "Liberyalı",
  "Libyalı",
  "Liechtensteinli",
  "Litvanyalı",
  "Lüksemburglu",
  "Makedon",
  "Madagaskarlı",
  "Malavili",
  "Malezyalı",
  "Maldivli",
  "Malili",
  "Maltalı",
  "Marshall Adaları",
  "Moritanyalı",
  "Mauritiuslu",
  "Meksikalı",
  "Mikronezyalı",
  "Moldovalı",
  "Monakolu",
  "Moğol",
  "Faslı",
  "Lesotholu",
  "Botsvanalı",
  "Mozambikli",
  "Namibyalı",
  "Naurulu",
  "Nepalli",
  "Yeni Zelandalı",
  "Nikaragualı",
  "Nijeryalı",
  "Nijerli",
  "Kuzey Koreli",
  "Kuzey İrlandalı",
  "Norveçli",
  "Ummanlı",
  "Pakistanlı",
  "Palaulu",
  "Panamalı",
  "Papua Yeni Ginelı",
  "Paraguaylı",
  "Perulu",
  "Polonyalı",
  "Portekizli",
  "Katarlı",
  "Romanyalı",
  "Ruandalı",
  "Saint Lucialı",
  "El Salvadorlu",
  "Samoa'lı",
  "San Marinolu",
  "Sao Tome ve Principe'li",
  "Suudi",
  "İskoç",
  "Senegalli",
  "Sırp",
  "Seyşellı",
  "Sierra Leoneli",
  "Singapurlu",
  "Slovak",
  "Sloven",
  "Solomon Adaları",
  "Somalili",
  "Güney Afrikalı",
  "Güney Koreli",
  "İspanyol",
  "Sri Lankalı",
  "Sudanlı",
  "Surinamlı",
  "Svazi",
  "İsveçli",
  "İsviçreli",
  "Suriyeli",
  "Tayvanlı",
  "Tacik",
  "Tanzanyalı",
  "Taylandlı",
  "Togolu",
  "Tongalı",
  "Trinidad veya Tobagolu",
  "Tunuslu",
  "Tuvalu",
  "Ugandalı",
  "Ukraynalı",
  "Uruguaylı",
  "Venezuellalı",
  "Vietnamlı",
  "Gallerli",
  "Yemenli",
  "Zambialı",
  "Zimbabweli",
];
