import React, { useEffect, useState } from "react";
// @material-ui/core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { useSelector } from "react-redux";
import { getAllSearchers, getHeaderTitlesByLanguage } from "./helper";
import GenericCard from "components/GenericCard/GenericCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Searchers() {
  const [allSearchers, setAllSearchers] = useState([]);
  const language = useSelector(
    (r_state) => r_state.SettingResponse.language.members
  );
  const history = useHistory();

  const getSearchers = async () => {
    const response = await getAllSearchers();
    setAllSearchers(response);
  };

  useEffect(() => {
    getSearchers();
  }, []);

  const handleOnClickRowAll = (index, data) => {
    const user = data[data.length - 1];
    history.push("/admin/internal/user/", user.user);
  };

  const tableHeader = getHeaderTitlesByLanguage(language);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GenericCard
          tableHeader={tableHeader}
          tableRow={allSearchers}
          onPressTableRow={handleOnClickRowAll}
        />
      </GridItem>
    </GridContainer>
  );
}
