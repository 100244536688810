export const DUMY_JOBS = [
  {
    key: "babysitter",
    title: "Babysitter",
  },
  {
    key: "gardener",
    title: "Gardener",
  },
  {
    key: "driver",
    title: "Driver",
  },
];

export const DUMMY_JOB_QUESTION_RESPONSE = {
  key: "babysitter",
  title: "Babysitter",
  fields: [
    {
      type: "multipleChoice",
      key: "age",
      label: "Preferred age of babysitter",
      choices: ["18-25", "26-35", "36-45", "46+"],
    },
    {
      type: "range",
      key: "numberOfHours",
      label: "Number of hours needed per week",
      min: 1,
      max: 40,
    },
    {
      type: "singleChoice",
      key: "experience",
      label: "Preferred experience level",
      choices: ["Beginner", "Intermediate", "Advanced"],
    },
    {
      type: "text",
      key: "other",
      label: "Anything to add ",
    },
  ],
};
