import React, { useEffect, useState } from "react";
// @material-ui/core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Dialog from "components/Dialog/Dialog";
import NewAccountDialog from "./NewAccountDialog";
import { useSelector } from "react-redux";
import { getAllMembers, getHeaderTitlesByLanguage } from "./helper";
import { useHistory } from "react-router-dom";
import GenericCard from "components/GenericCard/GenericCard";

export default function Members() {
  const [allMembers, setAllMembers] = useState([]);
  const history = useHistory();
  const language = useSelector(
    (r_state) => r_state.SettingResponse.language.members
  );
  const [newAccountDialog, setNewAccountDialog] = useState(false);

  const getMembers = async () => {
    const response = await getAllMembers();
    setAllMembers(response);
  };

  useEffect(() => {
    getMembers();
  }, []);

  const handleOnClickRowAll = (index, data) => {
    const user = data[data.length - 1];
    console.log(user);
    history.push("/admin/internal/user/", user.user);
  };

  const tableHeader = getHeaderTitlesByLanguage(language);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GenericCard
          rightButtonText={language.addMember}
          rightButtonOnPress={() => setNewAccountDialog(true)}
          tableHeader={tableHeader}
          tableRow={allMembers}
          onPressTableRow={handleOnClickRowAll}
        />
      </GridItem>
      <Dialog
        open={newAccountDialog}
        onClose={() => setNewAccountDialog(false)}
      >
        <NewAccountDialog
          refresh={getMembers}
          close={() => setNewAccountDialog(false)}
        />
      </Dialog>
    </GridContainer>
  );
}
