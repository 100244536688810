/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Report from "@material-ui/icons/Report";
// import FitnessIcon from "@material-ui/icons/FitnessCenter";
// import TemplateIcon from "@material-ui/icons/Pages";
// import Message from "@material-ui/icons/Message";
// import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import UserProfile from "views/UserProfile/UserProfile.js";
import Survey from "views/Survey/Survey";
import Members from "views/Members/Members.js";
import Searchers from "views/Searchers/Searchers.js";
// import Courses from "views/Courses/Courses";
// import CourseDetail from "views/CourseDetail/CourseDetail";
// import ProgramDetail from "views/ProgramDetail/ProgramDetail";
// import CoursePackageDetail from "views/CoursePackageDetail/CoursePackageDetail";
// import Templates from "views/Templates/Templates";
// import Teachers from "views/Teachers/Teachers";
// import TeacherProfile from "views/TeacherProfile/TeacherProfile";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import Login from "views/Login/Login";
import Reports from "views/Reports/Reports";
// import NotificationCenter from "views/NotificationCenter/NotificationCenter";
// import Messaging from "views/Messaging/Messaging";

const dashboardRoutes = [
  {
    path: "/members",
    name: "members",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Members,
    layout: "/admin",
  },
  {
    path: "/searchers",
    name: "searchers",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Searchers,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "reports",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Report,
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "user",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin/internal",
  },
  {
    path: "/form",
    name: "form",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Survey,
    layout: "/admin/internal",
  },
  {
    path: "/rtl-page",
    name: "rtlSapport",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl",
  },
  {
    path: "/login",
    name: "login",
    rtlName: "التطور للاحترافية",
    icon: Language,
    component: Login,
    layout: "/landing",
  },
];

export default dashboardRoutes;
