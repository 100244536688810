export const COURSE_TYPES = {
  GROUP: "group",
  INDIVIDUAL: "individual",
};

export const CURRENCY = {
  DOLLAR: "$",
  TL: "₺",
};

export const PACKAGE_TYPES = {
  ONE_TIME: { type: "ONE_TIME", week: 1 },
  WEEKLY: { type: "WEEKLY", week: 1 },
  MONTHLY: { type: "MONTHLY", week: 4 },
  THREE_MONTHLY: { type: "THREE_MONTHLY", week: 12 },
  SIX_MONTHLY: { type: "SIX_MONTHLY", week: 24 },
  YEARLY: { type: "YEARLY", week: 52 },
};

export const DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
