import React, { useRef, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function GoogleAutoComleteQuestion({
  question,
  answer,
  onSelect,
}) {
  const [address, setAddress] = useState("");
  const inputRef = useRef();
  const [modalOpened, setModalOpened] = useState(false);

  const [latitude, longitude, addr] = answer?.feedback || ["", "", ""];

  const onChangeText = (lat, lng, addres) => {
    onSelect?.(question.key, `${lat}|${lng}|${addres}`);
  };

  const handleSelect = (addressData) => {
    geocodeByAddress(addressData)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => onChangeText(latLng.lat, latLng.lng, addressData))
      .catch((error) => console.error("Error", error));
  };

  return (
    <>
      <CustomInput
        labelText={question.placeholder}
        id="email-address"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          inputRef: inputRef,
          value: addr,
          onFocus: () => {
            if (!latitude) {
              setModalOpened(true);
              inputRef?.current?.blur();
            }
          },
          onChange: (e) => onChangeText(latitude, longitude, e.target.value),
        }}
      />
      <Button onClick={() => setModalOpened(true)} round color="primary">
        Konum Bul
      </Button>
      {modalOpened ? (
        <div style={{ padding: 0, marginTop: 20 }}>
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className: "location-search-input",
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <Button onClick={() => setModalOpened(false)} color="primary">
            Kapat
          </Button>
        </div>
      ) : null}
      {/* <Dialog open={modalOpened} onClose={() => setModalOpened(false)}>

        <div style={{ width: 500, height: 500, padding: 20 }}>
          <AutoComplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
            onPlaceSelected={(place) => {
              const lat = place.geometry.lat();
              const lng = place.geometry.lng();
              const address = place.address_components[0]?.long_name;
              console.log(place);
              onChangeText(lat, lng, address);
            }}
          />
          <Button onClick={() => setModalOpened(true)} color="primary">
            Kapat
          </Button>
        </div>
      </Dialog> */}
    </>
  );
}
