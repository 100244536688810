import React from "react";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";

export default function IncrementalQuestion({ question, answer, onSelect }) {
  const findChoiceValue = (choiceKey) => {
    const found = answer?.feedback.find(
      (item) => item.split("|")[0] === choiceKey
    );
    if (!found) {
      return 0;
    }
    return found.split("|")[1];
  };

  const onSelectPicker = (newAnswer) => {
    onSelect?.(question.key, newAnswer);
  };

  return (
    <GridContainer>
      {question.choices?.map((choice) => {
        const value = findChoiceValue(choice.key);

        const onChangeText = (text) => {
          onSelectPicker(`${choice.key}|${text}`);
        };

        return (
          <GridItem xs={12} sm={4} md={4}>
            <CustomInput
              labelText={choice.label}
              id={choice.label}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: value,
                onChange: (e) => onChangeText(e.target.value),
              }}
              multiline={question.multiline}
            />
          </GridItem>
        );
      })}
    </GridContainer>
  );
}
