import { useState } from "react";

export default function useSurveyHook({ survey, initialDetails }) {
  const [answers, setAnswers] = useState([]);

  const initQuestions = (questions) => {
    const surveyInitialAnswers = [];
    questions.forEach((page) => {
      page.questions.forEach((q) => {
        if (q.defaultValue && !Array.isArray(q.defaultValue)) {
          surveyInitialAnswers.push({
            key: q.key,
            inputType: q.inputType,
            feedback: [q.defaultValue],
          });
        } else if (q.defaultValue && Array.isArray(q.defaultValue)) {
          surveyInitialAnswers.push({
            key: q.key,
            inputType: q.inputType,
            feedback: q.defaultValue,
          });
        }
      });
    });
    setAnswers(surveyInitialAnswers);
  };

  const initAnswers = () => {
    const initialAnsers = convertUserrDetailToAnswer(initialDetails);
    setAnswers(initialAnsers);
  };

  const onSelectAnswer = (questionKey, answer) => {
    const allQuestions = [].concat.apply(
      [],
      survey.map((item) => item.questions)
    );
    const question = allQuestions.find((item) => item.key === questionKey);
    if (!question) {
      return;
    }
    const copyAnswers = [...answers];
    const answerIndex = copyAnswers.findIndex(
      (item) => item.key === questionKey
    );
    const newAnswer = getNewAnswerByAnswer(
      answer,
      question,
      copyAnswers[answerIndex]
    );
    if (answerIndex < 0) {
      copyAnswers.push(newAnswer);
    } else {
      copyAnswers[answerIndex] = newAnswer;
    }

    setAnswers(copyAnswers);
  };

  const getAnswerByQuestion = (questionKey) => {
    const answerData = answers.find((item) => item.key === questionKey);
    return answerData ? { ...answerData } : undefined;
  };

  const totalStep = survey.length;

  return {
    answers,
    totalStep,
    getAnswerByQuestion,
    onSelectAnswer,
    initQuestions,
    initAnswers,
  };
}

export const getNewAnswerByAnswer = (newAnswer, question, answer) => {
  if (question.inputType === "location") {
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: newAnswer.split("|"),
    };
  }
  if (question.inputType === "single-choice" && newAnswer.includes("|")) {
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: newAnswer.split("|"),
    };
  }
  if (question.inputType === "google-autocomplete") {
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: newAnswer.split("|"),
    };
  }
  if (question.inputType === "range") {
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: [...newAnswer.split("|")], // first index low second index high
    };
  }
  if (question.inputType === "two-input-single-choice") {
    const splited = newAnswer.split("|");
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: [splited[0], splited[1]],
    };
  }
  if (question.inputType === "day-month-year") {
    const splited = newAnswer.split("|");
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: [splited[0], splited[1], splited[2]],
    };
  }
  if (question.inputType === "two-text-input-single-choice") {
    const splited = newAnswer.split("|");
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: [splited[0], splited[1]],
    };
  }
  if (question.inputType === "multi-choice") {
    const feedbacks = [...newAnswer];
    //const newAnswerIndex = feedbacks.findIndex((item) => item === newAnswer);
    // if (newAnswerIndex < 0) {
    //   return {
    //     ...answer,
    //     feedback: [...feedbacks, newAnswer],
    //   };
    // }
    // feedbacks.splice(newAnswerIndex, 1);
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: [...feedbacks],
    };
  }
  if (question.inputType === "phone") {
    const phoneNumerCodeArray = newAnswer.split("|");
    const phoneNumber = phoneNumerCodeArray[0];
    const countryCode = phoneNumerCodeArray[1];
    const strippedPhoneNumber = phoneNumber.startsWith(countryCode)
      ? phoneNumber.slice(countryCode.length)
      : phoneNumber;

    return {
      key: question.key,
      inputType: question.inputType,
      feedback: [strippedPhoneNumber, countryCode],
    };
  }
  if (!answer) {
    return {
      key: question.key,
      inputType: question.inputType,
      feedback: [newAnswer],
    };
  }

  if (question.inputType === "incremental") {
    const feedbacks = [...answer.feedback];
    const newAnswerIndex = feedbacks.findIndex(
      (item) => item.split("|")[0] === newAnswer.split("|")[0]
    );
    if (newAnswerIndex < 0) {
      return {
        ...answer,
        feedback: [...feedbacks, newAnswer],
      };
    }
    feedbacks.splice(newAnswerIndex, 1);
    return {
      ...answer,
      feedback: [...feedbacks, newAnswer],
    };
  }

  if (question.inputType === "switch-extra-question") {
    const splited = newAnswer.split("|");
    return {
      ...answer,
      feedback: [splited[0], splited[1]],
    };
  }
  if (question.inputType === "radio-select") {
    const splited = newAnswer.split("|");
    return {
      ...answer,
      feedback: [splited[0], splited[1]],
    };
  }

  return {
    ...answer,
    feedback: [newAnswer],
  };
};

const isEmpty = (data) => {
  if (!data) {
    return true;
  }
  if (Array.isArray(data) && data.length === 0) {
    return true;
  }
  if (Object.keys(data).length === 0) {
    return true;
  }
  return false;
};

const convertUserrDetailToAnswer = (userDetail) => {
  const answers = [];
  if (userDetail?.phone) {
    answers.push({
      key: "phone",
      inputType: "phone",
      feedback: [userDetail.phone, userDetail.phoneCountry],
    });
  }
  if (userDetail?.gender) {
    answers.push({
      key: "gender",
      inputType: "single-choice",
      feedback: [userDetail.gender],
    });
  }
  if (userDetail?.birthDate) {
    answers.push({
      key: "birthDate",
      inputType: "date",
      feedback: [userDetail.birthDate.split("T")[0]],
    });
  }
  if (userDetail?.nationality) {
    answers.push({
      key: "nationality",
      inputType: "single-choice",
      feedback: [userDetail.nationality],
    });
  }
  if (userDetail?.language) {
    answers.push({
      key: "language",
      inputType: "multi-choice",
      feedback: [...userDetail.language],
    });
  }
  if (userDetail?.maritalStatus) {
    answers.push({
      key: "maritalStatus",
      inputType: "multi-choice",
      feedback: [userDetail.maritalStatus],
    });
  }
  if (userDetail?.residencePermit) {
    answers.push({
      key: "residencePermit",
      inputType: "multi-choice",
      feedback: [userDetail.residencePermit],
    });
  }
  if (userDetail?.workPermit) {
    answers.push({
      key: "workPermit",
      inputType: "multi-choice",
      feedback: [userDetail.workPermit],
    });
  }
  if (userDetail?.education?.type) {
    answers.push({
      key: "education_type",
      inputType: "multi-choice",
      feedback: [userDetail?.education?.type],
    });
  }
  if (userDetail?.education?.department) {
    answers.push({
      key: "education_department",
      inputType: "multi-choice",
      feedback: [userDetail?.education?.department],
    });
  }
  if (userDetail?.location?.distance) {
    answers.push({
      key: "distance",
      inputType: "multi-choice",
      feedback: [userDetail?.location?.distance],
    });
  }
  if (userDetail?.location?.postalCode) {
    answers.push({
      key: "postalCode",
      inputType: "multi-choice",
      feedback: [userDetail?.location?.postalCode],
    });
  }
  if (userDetail?.shortQuestions?.howManyYearsInCurrentCountry) {
    answers.push({
      key: "howManyYearsInCurrentCountry",
      inputType: "multi-choice",
      feedback: [
        userDetail?.shortQuestions?.howManyYearsInCurrentCountry?.day || "0",
        userDetail?.shortQuestions?.howManyYearsInCurrentCountry?.month || "0",
        userDetail?.shortQuestions?.howManyYearsInCurrentCountry?.year || "0",
      ],
    });
  }
  if (
    userDetail?.shortQuestions?.likePet === true ||
    userDetail?.shortQuestions?.likePet === false
  ) {
    answers.push({
      key: "likePet",
      inputType: "multi-choice",
      feedback: [userDetail?.shortQuestions?.likePet ? "yes" : "no"],
    });
  }
  if (
    userDetail?.shortQuestions?.reference === true ||
    userDetail?.shortQuestions?.reference === false
  ) {
    answers.push({
      key: "reference",
      inputType: "multi-choice",
      feedback: [userDetail?.shortQuestions?.reference ? "yes" : "no"],
    });
  }
  if (
    userDetail?.shortQuestions?.smoking === true ||
    userDetail?.shortQuestions?.smoking === false
  ) {
    answers.push({
      key: "smoking",
      inputType: "multi-choice",
      feedback: [userDetail?.shortQuestions?.smoking ? "yes" : "no"],
    });
  }

  if (
    userDetail?.shortQuestions?.knowSwimming === true ||
    userDetail?.shortQuestions?.knowSwimming === false
  ) {
    answers.push({
      key: "knowSwimming",
      inputType: "multi-choice",
      feedback: [userDetail?.shortQuestions?.knowSwimming ? "yes" : "no"],
    });
  }

  if (
    userDetail?.shortQuestions?.drivingLicence === true ||
    userDetail?.shortQuestions?.drivingLicence === false
  ) {
    answers.push({
      key: "drivingLicence",
      inputType: "multi-choice",
      feedback: [userDetail?.shortQuestions?.drivingLicence ? "yes" : "no"],
    });
  }

  if (
    userDetail?.shortQuestions?.hadCovid === true ||
    userDetail?.shortQuestions?.hadCovid === false
  ) {
    answers.push({
      key: "hadCovid",
      inputType: "multi-choice",
      feedback: [userDetail?.shortQuestions?.hadCovid ? "yes" : "no"],
    });
  }

  if (
    userDetail?.shortQuestions?.vaccinated === true ||
    userDetail?.shortQuestions?.vaccinated === false
  ) {
    answers.push({
      key: "vaccinated",
      inputType: "multi-choice",
      feedback: [userDetail?.shortQuestions?.vaccinated ? "yes" : "no"],
    });
  }

  if (userDetail?.shortQuestions?.willingToGoVacationWithFamily) {
    let extraAnswers = [];

    const pushedData = {
      key: "willingToGoVacationWithFamily",
      inputType: "multi-choice",
      feedback: [
        userDetail?.shortQuestions?.willingToGoVacationWithFamily.response
          ? "yes"
          : "no",
      ],
    };
    // Eğer extra question answer varsa
    if (
      userDetail?.shortQuestions?.willingToGoVacationWithFamily.response &&
      userDetail?.shortQuestions?.willingToGoVacationWithFamily.whichMonths
    ) {
      extraAnswers.push({
        key: "whichMonths",
        inputType: "multi-choice",
        feedback:
          userDetail?.shortQuestions?.willingToGoVacationWithFamily.whichMonths,
      });
    }
    if (
      userDetail?.shortQuestions?.willingToGoVacationWithFamily.response &&
      userDetail?.shortQuestions?.willingToGoVacationWithFamily.totalTime
    ) {
      extraAnswers.push({
        key: "howLong",
        inputType: "multi-choice",
        feedback: [
          userDetail?.shortQuestions?.willingToGoVacationWithFamily.totalTime
            .day,
          userDetail?.shortQuestions?.willingToGoVacationWithFamily.totalTime
            .month,
        ],
      });
    }
    pushedData.feedback.push(JSON.stringify(extraAnswers));
    answers.push(pushedData);
  }

  if (userDetail?.houseType) {
    answers.push({
      key: "houseType",
      inputType: "multi-choice",
      feedback: userDetail?.houseType,
    });
  }

  if (userDetail?.salary) {
    answers.push({
      key: "salary",
      inputType: "multi-choice",
      feedback: [userDetail?.salary?.min, userDetail?.salary?.max || ""],
    });
  }

  if (userDetail?.salary?.currency) {
    answers.push({
      key: "currency",
      inputType: "multi-choice",
      feedback: [userDetail?.salary.currency],
    });
  }

  if (userDetail?.whenToStart) {
    answers.push({
      key: "whenToStart",
      inputType: "multi-choice",
      feedback: [userDetail?.whenToStart],
    });
  }

  if (userDetail?.weekDays) {
    answers.push({
      key: "weekDays",
      inputType: "multi-choice",
      feedback: userDetail?.weekDays,
    });
  }

  if (userDetail?.workType) {
    answers.push({
      key: "workType",
      inputType: "multi-choice",
      feedback: userDetail?.workType,
    });
  }
  if (userDetail.totalExperience) {
    console.log("Total Experience: ", userDetail.totalExperience);
    answers.push({
      key: "total_experience",
      inputType: "two-text-input-single-choice",
      feedback: [
        Math.floor(userDetail.totalExperience / 12).toString(),
        (userDetail.totalExperience % 12).toString(),
      ],
    });
  }

  if (userDetail?.note) {
    answers.push({
      key: "note",
      inputType: "multi-choice",
      feedback: [userDetail?.note],
    });
  }

  if (userDetail?.birthPlace) {
    answers.push({
      key: "birthPlace",
      inputType: "multi-choice",
      feedback: ["", "", userDetail?.birthPlace],
    });
  }

  if (userDetail?.location) {
    answers.push({
      key: "location",
      inputType: "multi-choice",
      feedback: [
        userDetail?.location.coordinates[0],
        userDetail?.location.coordinates[1],
        userDetail?.location.placeName,
      ],
    });
    if (userDetail?.familyMembers) {
      const feedback = [];
      if (userDetail?.familyMembers.kid) {
        feedback.push(`kid|${userDetail?.familyMembers.kid}`);
      }
      if (userDetail?.familyMembers.adult) {
        feedback.push(`adult|${userDetail?.familyMembers.adult}`);
      }
      if (userDetail?.familyMembers.personnel) {
        feedback.push(`personnel|${userDetail?.familyMembers.personnel}`);
      }
      answers.push({
        key: "familyMembers",
        inputType: "incremental",
        feedback: feedback,
      });
    }
  }
  return answers;
};
