import { SnackbarHelper } from "../../helpers";
import ApiHelper from "../../helpers/ApiHelper";
import SessionHelper from "../../helpers/SessionHelper";
import store from "store";
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";

export const login = async (username, password) => {
  const state = store.getState().SettingResponse;
  if (!username || !password) {
    SnackbarHelper.showSnackbar(
      state.language.login.emptyField,
      SnackbarHelper.colors.danger
    );
    return false;
  }
  const response = await APIEndpointHelper.login(username, password);
  console.log(response);
  if (!response.success) {
    SnackbarHelper.showSnackbar(response.data, SnackbarHelper.colors.danger);
    return false;
  }
  console.log("TOKEN : ", response.data.accessToken)
  SessionHelper.setUser(response.data);
  ApiHelper.setAccessToken(response.data.accessToken);
  return true;
};
