import React from "react";
import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector } from "react-redux";

export default function LoadingDialog() {
  const opened = useSelector(
    (r_state) => r_state.ComponentResponse.loadingDialog
  );

  const handleClose = () => {};

  return (
    <Dialog
      open={opened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //   style={{ width: 100, height: 100 }}
    >
      <DialogTitle id="alert-dialog-title">Yükleniyor...</DialogTitle>
      {/* <DialogContent style={{ width: 200, height: 200 }}></DialogContent> */}
    </Dialog>
  );
}

LoadingDialog.propTypes = {};
