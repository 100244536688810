import React, { useState } from "react";
// core components
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { useSelector } from "react-redux";
import { createAccount } from "./helper";
import GenericCard from "components/GenericCard/GenericCard";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import CustomPhoneInput from "components/CustomPhoneInput/CustomPhoneInput";
import validatePhoneNumber from "helpers/validatePhoneNumber";

const useStyles = makeStyles(styles);

export default function NewAccountDialog({ refresh, close }) {
  const language = useSelector(
    (r_state) => r_state.SettingResponse.language.userProfile
  );
  const [loading, setLoading] = useState(false);
  const [userinfo, setUserinfo] = useState({});
  const [phoneCountry, setPhoneCountry] = useState("90");
  const handleOnPhoneChange = (value, country) => {
    handleAccountInfoChange("phone", value);
    setPhoneCountry(country.dialCode);
  };

  const classes = useStyles();

  const handleAccountInfoChange = (key, value) => {
    const newUserInfo = { ...userinfo };
    newUserInfo[key] = value;
    setUserinfo(newUserInfo);
  };

  const onPressUpdateAccount = async () => {
    if ((!userinfo.email && userinfo.appUser) || !isPhoneValid) {
      return;
    }
    setLoading(true);
    const strippedPhoneNumber = userinfo.phone.startsWith(phoneCountry)
      ? userinfo.phone.slice(phoneCountry.length)
      : userinfo.phone;

    const insertedData = {
      user: {
        name: `${userinfo.name} ${userinfo.surname}`,
        email: userinfo.email,
        appUser: !!userinfo.appUser,
        phone: strippedPhoneNumber,
        phoneCountry,
      },
      provider: {},
    };
    const response = await createAccount(insertedData);
    if (response) {
      refresh();
      close();
    }
    setLoading(false);
  };
  const emailRequired = !userinfo.email && userinfo.appUser;
  const isPhoneValid =
    userinfo.phone && validatePhoneNumber(`+${userinfo.phone}`).isValid;
  // console.log("Email Required : ", emailRequired);
  console.log("isPhoneValid : ", isPhoneValid);
  console.log("phone: ", userinfo.phone);
  console.log("phoneCountry: ", userinfo.phoneCountry);
  return (
    <GridItem xs={12} sm={12} md={12}>
      <GenericCard
        title={language.createAccount}
        subtitle={language.createAccountSubtitle}
        renderFooter={() => (
          <Button
            loading={loading}
            onClick={onPressUpdateAccount}
            color={emailRequired || !isPhoneValid ? undefined : "primary"}
            disabled={emailRequired || !isPhoneValid}
          >
            {language.createAccount}
          </Button>
        )}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={language.firstname}
              id="first-name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.name,
                onChange: (e) =>
                  handleAccountInfoChange("name", e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={language.lastname}
              id="last-name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.surname,
                onChange: (e) =>
                  handleAccountInfoChange("surname", e.target.value),
              }}
            />
          </GridItem>
        </GridContainer>
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={language.username}
              id="username"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.username,
                onChange: (e) =>
                  handleAccountInfoChange("username", e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText={language.phone}
              id="phone"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.phone,
                onChange: (e) =>
                  handleAccountInfoChange("phone", e.target.value),
              }}
            />
          </GridItem>
        </GridContainer> */}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={language.email}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.email,
                onChange: (e) =>
                  handleAccountInfoChange("email", e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {/* <CustomInput
              labelText={language.phone}
              id="phone"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.phone,
                onChange: (e) =>
                  handleAccountInfoChange("phone", e.target.value),
              }}
            /> */}
            <CustomPhoneInput
              value={userinfo.phone}
              onChange={handleOnPhoneChange}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Checkbox
                checked={userinfo.appUser}
                // tabIndex={-1}
                onClick={() =>
                  handleAccountInfoChange("appUser", !userinfo.appUser)
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.root,
                }}
              />
              <p>{language.app_user}</p>
            </div>
            {emailRequired ? (
              <p style={{ color: "red" }}>{language.email_required}</p>
            ) : null}
          </GridItem>
        </GridContainer>
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={language.password}
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: userinfo.password,
                onChange: (e) =>
                  handleAccountInfoChange("password", e.target.value),
              }}
            />
          </GridItem>
        </GridContainer> */}
      </GenericCard>
    </GridItem>
  );
}

NewAccountDialog.propTypes = {
  refresh: PropTypes.func,
  close: PropTypes.func,
};
