import React from "react";
import store from "store";
import moment from "moment";
import SnackBar from "../../helpers/SnackbarHelper";
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";

export const getAllReports = async () => {
  // const { data } = await getAllMembersFromService();

  const data = await APIEndpointHelper.getReports();
  const language = store.getState().SettingResponse.language.members;
  const shownProperties = [
    "_id",
    "postedBy",
    "postedTo",
    "reportTitle",
    "createdAt",
    // "password",
    // "createdAt",
    // "gdpr",
    // "status",
  ];
  const rows = [];

  if (!data.data) {
    return rows;
  }
  data.data
    .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())
    .forEach((item) => {
      const row = [];
      shownProperties.forEach((property) => {
        let addedProp = item[property];

        if (!addedProp && property === "createdAt") {
          addedProp = <p style={{ color: "red" }}>{"Tarih Yok"}</p>;
        } else if (property === "createdAt") {
          addedProp = moment(addedProp).format("Do MMMM YY HH:mm");
        }
        if (!addedProp && property === "postedBy") {
          addedProp = "-";
        } else if (property === "postedBy") {
          addedProp = addedProp.name;
        }

        if (!addedProp && property === "postedTo") {
          addedProp = "-";
        } else if (property === "postedTo") {
          addedProp = addedProp.name;
        }

        row.push(addedProp || "");
      });
      row.push(item);
      rows.push(row);
    });

  return rows;
};

export const convertDataToTableRow = (data) => {
  const shownProperties = [
    "_id",
    "postedBy",
    "postedTo",
    "reportTitle",
    "createdAt",
  ];
  const rows = [];

  if (!data) {
    return rows;
  }
  console.log(data);
  data
    .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())
    .forEach((item) => {
      const row = [];
      shownProperties.forEach((property) => {
        let addedProp = item[property];

        if (!addedProp && property === "createdAt") {
          addedProp = <p style={{ color: "red" }}>{"Tarih Yok"}</p>;
        } else if (property === "createdAt") {
          addedProp = moment(addedProp).format("Do MMMM YY HH:mm");
        }
        if (!addedProp && property === "postedBy") {
          addedProp = "-";
        } else if (property === "postedBy") {
          addedProp = addedProp.name;
        }

        if (!addedProp && property === "postedTo") {
          addedProp = "-";
        } else if (property === "postedTo") {
          addedProp = addedProp.name;
        }

        row.push(addedProp || "");
      });
      row.push(item);
      rows.push(row);
    });

  return rows;
};

export const getUniqueReportedBy = (data) => {
  const result = [];
  if (!data?.data) {
    return [];
  }
  data.data
    .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())
    .forEach((item) => {
      if (item.postedBy) {
        const found = result.find((x) => x.key === item.postedBy._id);
        if (!found) {
          result.push({ key: item.postedBy._id, label: item.postedBy.name });
        }
      }
    });
  return result;
};

export const getHeaderTitlesByLanguage = (language) => {
  return [
    language.id,
    language.postedBy,
    language.postedTo,
    language.title,
    language.created_at,

    // language.gdpr_status,
    // language.status,
  ];
};

export const createAccount = async (data) => {
  const languageType = store.getState().SettingResponse.languageType;
  const response = await APIEndpointHelper.addNewProvider(data);
  if (response.success) {
    SnackBar.showSnackbar(
      response.Message[languageType] || "İşlem Başarılı",
      SnackBar.colors.success
    );
    return true;
  }
  if (response.Status === 4 || response.Status === 3) {
    SnackBar.showSnackbar(
      response.Message[languageType],
      SnackBar.colors.danger
    );
    return false;
  }
};
