import React, { useEffect, useState } from "react";
// @material-ui/core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { useSelector } from "react-redux";
import {
  getUniqueReportedBy,
  convertDataToTableRow,
  getHeaderTitlesByLanguage,
} from "./helper";
import GenericCard from "components/GenericCard/GenericCard";
import { Dialog, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";

export default function Reports() {
  const [allReports, setAllReports] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [postedBy, setPostedBy] = useState();
  const [postedTo, setPostedTo] = useState();
  const language = useSelector(
    (r_state) => r_state.SettingResponse.language.members
  );

  const getReports = async () => {
    const response = await APIEndpointHelper.getReports();
    setAllReports(response);
  };

  useEffect(() => {
    getReports();
  }, []);

  const handleOnClickRowAll = (index, data) => {
    const allData = data[data.length - 1];
    setSelectedReport(allData);
  };

  const handleChangePostedBy = (event, newValue) => {
    setPostedBy(newValue);
  };

  const handleChangePostedTo = (event, newValue) => {
    setPostedTo(newValue);
  };
  let reports = allReports?.data || [];
  if (postedBy) {
    reports = reports.filter((item) => item.postedBy?._id === postedBy.key);
  }
  if (postedTo) {
    reports = reports.filter((item) => item.postedTo?._id === postedTo.key);
  }

  const rows = convertDataToTableRow(reports);

  const postedByOptions = getUniqueReportedBy(allReports);
  const postedToOptions = getUniqueReportedBy(allReports);

  const tableHeader = getHeaderTitlesByLanguage(language);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GenericCard
          color={"white"}
          tableHeader={tableHeader}
          tableRow={rows}
          onPressTableRow={handleOnClickRowAll}
          renderHeader={() => (
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Autocomplete
                  options={postedByOptions}
                  // style={{ backgroundColor: "white" }}
                  getOptionLabel={(option) => option.label}
                  onChange={handleChangePostedBy}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // style={{ backgroundColor: "white" }}
                      label="Raporlayan Seç"
                      variant="outlined"
                    />
                  )}
                  style={{ width: 300 }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Autocomplete
                  options={postedToOptions}
                  // style={{ backgroundColor: "white" }}
                  getOptionLabel={(option) => option.label}
                  onChange={handleChangePostedTo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // style={{ backgroundColor: "white" }}
                      label="Raporlanan Seç"
                      variant="outlined"
                    />
                  )}
                  style={{ width: 300 }}
                />
              </GridItem>
            </GridContainer>
          )}
        />
      </GridItem>
      <Dialog open={selectedReport} onClose={() => setSelectedReport(null)}>
        <div style={{ width: 600, padding: "20px" }}>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <p style={titleStyle}>{language.postedBy}</p>
              <p>{selectedReport?.postedBy?._id}</p>
              <p>{selectedReport?.postedBy?.name}</p>
              <p>{selectedReport?.postedBy?.email}</p>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <p style={titleStyle}>{language.postedTo}</p>
              <p>{selectedReport?.postedTo?._id}</p>
              <p>{selectedReport?.postedTo?.name}</p>
              <p>{selectedReport?.postedTo?.email}</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p style={titleStyle}>{language.reportId}</p>
              <p>{selectedReport?._id}</p>
              <p style={titleStyle}>{language.reportTitle}</p>
              <p>{selectedReport?.reportTitle}</p>
              <p style={titleStyle}>{language.reportDescription}</p>
              <p>{selectedReport?.reportDescription}</p>
            </GridItem>
          </GridContainer>
        </div>
      </Dialog>
    </GridContainer>
  );
}

const titleStyle = { fontWeight: "500" };
