import { JOB_KEY } from "./useDynamicJobHook";

export default function useProviderHook({ dynamicAnswers, answers }) {
  const getDynamicJobBody = () => {
    const jobKey = JOB_KEY;
    const jobAnswer = dynamicAnswers.find((item) => item.key === jobKey);
    const selectedJobs = jobAnswer?.feedback;
    if (!selectedJobs || selectedJobs.length === 0) {
      return [];
    }
    const jobs = [];
    selectedJobs.forEach((selectedJob) => {
      const jobQuestionChoices = [];

      dynamicAnswers.forEach((item) => {
        const [key, answerKey, qKey] = item.key.split("|");
        if (qKey === selectedJob) {
          jobQuestionChoices.push({
            key: answerKey,
            value: item.feedback || [],
          });
        }
      });
      jobs.push({ jobKey: selectedJob, jobOptions: jobQuestionChoices });
    });

    return jobs;
  };

  const buildRequestBody = () => {
    const phoneAnswer = answers.find((item) => item.key === "phone");

    const genderAnswer = answers.find((item) => item.key === "gender");
    const birthDateAnswer = answers.find((item) => item.key === "birthDate");
    const nationalityAnswer = answers.find(
      (item) => item.key === "nationality"
    );
    // const birthPlaceAnswer = answers.find((item) => item.key === "birthPlace");

    const languageAnswer = answers.find((item) => item.key === "language");
    const maritalStatusAnswer = answers.find(
      (item) => item.key === "maritalStatus"
    );
    const residencePermitAnswer = answers.find(
      (item) => item.key === "residencePermit"
    );
    const workPermitAnswer = answers.find((item) => item.key === "workPermit");
    const educationAnswer = answers.find(
      (item) => item.key === "education_type"
    );
    // const departmentAnswer = answers.find(
    //   (item) => item.key === "education_department"
    // );
    const petsAnswer = answers.find((item) => item.key === "pets");

    const locationAnswer = answers.find((item) => item.key === "location");
    const distanceAnswer = answers.find((item) => item.key === "distance");
    const postalCodeAnswer = answers.find((item) => item.key === "postalCode");

    const likePetAnswer = answers.find((item) => item.key === "likePet");
    const referenceAnswer = answers.find((item) => item.key === "reference");
    const smakingAnswer = answers.find((item) => item.key === "smoking");
    const swimmingAnswer = answers.find((item) => item.key === "knowSwimming");
    const drivingLicenceAnswer = answers.find(
      (item) => item.key === "drivingLicence"
    );
    const covidAnswer = answers.find((item) => item.key === "hadCovid");
    const vaccinatedAnswer = answers.find((item) => item.key === "vaccinated");
    const howManyYearsInCurrentCountryAnswer = answers.find(
      (item) => item.key === "howManyYearsInCurrentCountry"
    );

    const howManyYearsInCurrentCountryBody = {
      day: howManyYearsInCurrentCountryAnswer?.feedback[0] || "0",
      month: howManyYearsInCurrentCountryAnswer?.feedback[1] || "0",
      year: howManyYearsInCurrentCountryAnswer?.feedback[2] || "0",
    };
    const houseTypeAnswer = answers.find((item) => item.key === "houseType");
    const whenToStartAnswer = answers.find(
      (item) => item.key === "whenToStart"
    );
    const noteAnswer = answers.find((item) => item.key === "note");
    const salaryAnswer = answers.find((item) => item.key === "salary");
    const currencyAnswer = answers.find((item) => item.key === "currency");
    const experienceAnswer = answers.find(
      (item) => item.key === "total_experience"
    );

    const weekDaysAnswer = answers.find((item) => item.key === "weekDays");
    const workTypeAnswer = answers.find((item) => item.key === "workType");

    const familyMembersAnswer = answers.find(
      (item) => item.key === "familyMembers"
    );
    const familyMembersBody = {};
    familyMembersAnswer?.feedback.forEach((item) => {
      const splited = item.split("|");
      familyMembersBody[splited[0]] = parseInt(splited[1], 10);
    });

    const willingToGoVacationWithFamilyAnswer = answers.find(
      (item) => item.key === "willingToGoVacationWithFamily"
    );

    const willingToGoVacationBody = {
      response: willingToGoVacationWithFamilyAnswer?.feedback[0] === "yes",
    };
    const willingToGoVacationWithFamilyExtraAnswers = willingToGoVacationWithFamilyAnswer
      ?.feedback[1]
      ? JSON.parse(willingToGoVacationWithFamilyAnswer?.feedback[1])
      : [];
    willingToGoVacationWithFamilyExtraAnswers.forEach((answer) => {
      if (answer.key === "whichMonths") {
        willingToGoVacationBody["whichMonths"] = answer.feedback;
      }
      if (answer.key === "howLong") {
        console.log("Long :", answer.feedback);
        willingToGoVacationBody["totalTime"] = {
          month: answer.feedback[1],
          day: answer.feedback[0],
        };
      }
    });

    const dynamicBody = getDynamicJobBody();
    console.log(dynamicBody);
    const body = {
      phone: phoneAnswer?.feedback[0],
      phoneCountry: phoneAnswer?.feedback[1],
      gender: genderAnswer?.feedback[0],
      birthDate: birthDateAnswer?.feedback[0],
      nationality: nationalityAnswer?.feedback[0],
      // birthPlace: birthPlaceAnswer?.feedback[2],

      language: languageAnswer?.feedback,

      maritalStatus: maritalStatusAnswer?.feedback[0],
      residencePermit: residencePermitAnswer?.feedback[0],
      workPermit: workPermitAnswer?.feedback?.[0],
      familyMembers: familyMembersBody,
      pets: petsAnswer?.feedback,
      education: {
        type: educationAnswer?.feedback[0],
        // department: departmentAnswer?.feedback[0],
        other: "N/A",
      },

      location: {
        type: "Point",
        coordinates: [locationAnswer?.feedback[0], locationAnswer?.feedback[1]],
        placeName: locationAnswer?.feedback[2],
        distance: distanceAnswer?.feedback[0],
        postalCode: postalCodeAnswer?.feedback[0],
      },
      address: locationAnswer?.feedback[2],

      shortQuestions: {
        howManyYearsInCurrentCountry: howManyYearsInCurrentCountryBody,
        likePet: likePetAnswer?.feedback[0] === "yes",
        reference: referenceAnswer?.feedback[0] === "yes",
        smoking: smakingAnswer?.feedback[0] === "yes",
        knowSwimming: swimmingAnswer?.feedback[0] === "yes",
        drivingLicence: drivingLicenceAnswer?.feedback[0] === "yes",
        hadCovid: covidAnswer?.feedback[0] === "yes",
        vaccinated: vaccinatedAnswer?.feedback[0] === "yes",
        willingToGoVacationWithFamily: willingToGoVacationBody,
      },
      houseType: houseTypeAnswer?.feedback,

      whenToStart: whenToStartAnswer?.feedback[0],
      note: noteAnswer?.feedback[0],

      salary: {
        min: salaryAnswer?.feedback[0],
        max: salaryAnswer?.feedback[1],
        currency: currencyAnswer?.feedback[0],
      },
      totalExperience: {
        year: experienceAnswer?.feedback[0],
        month: experienceAnswer?.feedback[1],
      },
      weekDays: weekDaysAnswer?.feedback,
      workType: workTypeAnswer?.feedback,

      jobs: dynamicBody,
    };

    return body;
  };
  return { buildRequestBody };
}
