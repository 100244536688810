import moment from "moment";
import "moment/locale/tr";
import Languages from "assets/languages";
import {
  onLanguageChange,
  onLanguageTypeChange,
} from "store/actions/Setting.action";

class LanguageHelper {
  init() {
    moment.locale("tr");
    onLanguageChange(Languages.tr);
    onLanguageTypeChange("tr");
  }
}

export default new LanguageHelper();
