import React, { useMemo, useState } from "react";

import Dialog from "components/Dialog/Dialog";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";

import Maps from "./Maps";
import { getAddressFromLatLong } from "helpers/GeocoderHelper";

export default function LocationSelect({ question, answer, onSelect }) {
  const [lat, lng, addr] = answer?.feedback || ["", "", ""];

  const [region, setRegion] = useState({ lat: 0, lng: 0 });
  const [modalOpened, setModalOpeend] = useState(false);
  const [address, setAddress] = useState(addr);

  const onPressButton = () => {
    if (!region) {
      return;
    }
    onSelect?.(question.key, `${region.lat}|${region.lng}|${address}`);
    setModalOpeend(false);
  };

  const onSelectLocation = async (lat, lng) => {
    setRegion({ lat, lng });
    if (lat && lng) {
      const results = await getAddressFromLatLong(lat, lng);
      if (results[0]) {
        setAddress(results[0].formatted_address);
      }
    }
  };

  const renderMap = useMemo(
    () => <Maps height={500} onSelectLocation={onSelectLocation} />,
    []
  );

  return (
    <>
      <div
        style={{ padding: 20, backgroundColor: "#d3d3d3", borderRadius: 20 }}
        onClick={() => setModalOpeend(true)}
      >
        <p>{address}</p>
      </div>
      <Dialog open={modalOpened} onClose={() => setModalOpeend(false)}>
        <div style={{ width: 500, height: 500, padding: 20 }}>{renderMap}</div>
        <CustomInput
          labelText={question.placeholder}
          id="email-address"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: address,
            // onChange: (e) => onChangeText(e.target.value),
          }}
        />
        <Button onClick={onPressButton} color="primary">
          {question.locationButtonText}
        </Button>
      </Dialog>
    </>
  );
}
