import React from "react";
import CustomInput from "components/CustomInput/CustomInput";

export default function Textbox({ question, answer, onSelect }) {
  const value = answer?.feedback[0] || "";

  const onChangeText = (newAnswer) => {
    onSelect?.(question.key, newAnswer);
  };

  return (
    <CustomInput
      labelText={question.placeholder}
      id="email-address"
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: value,
        onChange: (e) => onChangeText(e.target.value),
      }}
      multiline={question.multiline}
    />
  );
}
