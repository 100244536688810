import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import { dangerColor } from "assets/jss/material-dashboard-react";
import GridItem from "components/Grid/GridItem";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardCategoryDanger: {
    color: dangerColor[0],
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function GenericCard({
  title,
  subtitle,
  rightButtonText,
  rightButtonOnPress,
  tableRow,
  tableHeader,
  onPressTableRow,
  color,
  children,
  renderFooter,
  renderHeader,
}) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color={color}>
        <GridContainer>
          <GridItem
            xs={rightButtonText ? 6 : 12}
            sm={rightButtonText ? 9 : 12}
            md={rightButtonText ? 9 : 12}
          >
            {title ? <h4 className={classes.cardTitleWhite}>{title}</h4> : null}
            {subtitle ? (
              <p className={classes.cardCategoryWhite}>{subtitle}</p>
            ) : null}
            {renderHeader?.()}
          </GridItem>
          {rightButtonText ? (
            <GridItem
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              xs={6}
              sm={3}
              md={3}
            >
              <Button onClick={rightButtonOnPress} round color="white">
                {rightButtonText}
              </Button>
            </GridItem>
          ) : null}
        </GridContainer>
      </CardHeader>
      <CardBody>
        {children}
        {tableRow ? (
          <Table
            tableHeaderColor={color}
            tableHead={tableHeader}
            tableData={tableRow}
            onClickRow={onPressTableRow}
          />
        ) : null}
      </CardBody>
      {renderFooter ? <CardFooter>{renderFooter()}</CardFooter> : null}
    </Card>
  );
}

GenericCard.defaultProps = {
  color: "primary",
};

GenericCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rightButtonText: PropTypes.string,
  rightButtonOnPress: PropTypes.func,
  tableRow: PropTypes.array,
  tableHeader: PropTypes.array,
  onPressTableRow: PropTypes.func,
  color: PropTypes.string,
  children: PropTypes.any,
  renderFooter: PropTypes.any,
};
