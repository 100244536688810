import React, { useEffect, useState } from "react";
// @material-ui/core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import GenericCard from "components/GenericCard/GenericCard";
import useProviderSurveyHook from "hooks/useProviderSurveyHook.js";
import Question from "./components/Question";
import useSurveyHook from "./useSurveyHook";
import useDynamicJobHook, { JOB_KEY } from "./useDynamicJobHook";
import Button from "components/CustomButtons/Button.js";
import useProviderHook from "./useProviderHook";
import { APIEndpointHelper } from "helpers/ApiEndpointHelper";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarHelper } from "helpers";
import validatePhoneNumber from "helpers/validatePhoneNumber";

export default function Survey() {
  const location = useLocation();
  const history = useHistory();

  const userId = location.state.userinfo._id;
  const initialDetailsOfUser = location.state.userDetails;

  const [dynamicSurvey, setDynamicSurvey] = useState([]);
  const { SURVEY_PAGES } = useProviderSurveyHook();
  const {
    answers,
    onSelectAnswer,
    getAnswerByQuestion,
    initAnswers,
  } = useSurveyHook({
    survey: SURVEY_PAGES,
    initialDetails: initialDetailsOfUser,
  });

  const {
    onSelectAnswer: onSelectAnswerDynamic,
    getAnswerByQuestion: getAnswerByQuestionDynamic,
    answers: dynamicAnswers,
    jobQuestion,
    jobs,
  } = useDynamicJobHook({
    survey: dynamicSurvey,
    answers: answers,
    initialdetails: initialDetailsOfUser,
    setSurvey: setDynamicSurvey,
  });

  const { buildRequestBody } = useProviderHook({ dynamicAnswers, answers });

  const onPressSend = async () => {
    const userPhoneArr = answers.find((answer) => answer.key === "phone")
      ?.feedback;
    const userPhone = `+${userPhoneArr[1]}${userPhoneArr[0]}`;

    const isPhoneValid = validatePhoneNumber(userPhone);

    if (!isPhoneValid.isValid) {
      return alert(isPhoneValid.message);
    }

    const reqBody = buildRequestBody();
    const result = await APIEndpointHelper.updateProviderDetail(
      userId,
      reqBody
    );
    if (result.success) {
      history.goBack();
      SnackbarHelper.showSnackbar(
        "İşlem başarılı",
        SnackbarHelper.colors.success
      );
    }
  };

  useEffect(() => {
    initAnswers();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <GenericCard>
          {SURVEY_PAGES.map((item) => {
            return (
              <GridContainer>
                {item.questions.map((q) => {
                  return (
                    <Question
                      question={q}
                      onSelect={onSelectAnswer}
                      answer={getAnswerByQuestion(q.key)}
                      answers={answers}
                    />
                  );
                })}
              </GridContainer>
            );
          })}
        </GenericCard>
      </GridItem>
      <GridItem xs={12} sm={6} md={6}>
        <GenericCard>
          <GridContainer>
            {jobs.length > 0 ? (
              <Question
                question={jobQuestion}
                onSelect={onSelectAnswerDynamic}
                answer={getAnswerByQuestionDynamic(jobQuestion.key)}
              />
            ) : null}
          </GridContainer>
          {dynamicSurvey.map((item) => {
            return (
              <GridContainer>
                {item.questions.map((q) => {
                  return (
                    <Question
                      question={q}
                      onSelect={onSelectAnswerDynamic}
                      answer={getAnswerByQuestionDynamic(q.key)}
                    />
                  );
                })}
              </GridContainer>
            );
          })}
        </GenericCard>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Button fullWidth onClick={onPressSend} color="primary">
          {"Gönder"}
        </Button>
      </GridItem>
    </GridContainer>
  );
}
