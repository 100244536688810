/* eslint-disable react-hooks/exhaustive-deps */
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";

export default function RangeSelect({ question, onSelect, answer, answers }) {
  const onChangeLow = (low) => {
    onSelect?.(question.key, `${low}|${answer?.feedback[1] || question.max}`);
  };

  const onChangeHigh = (high) => {
    onSelect?.(question.key, `${answer?.feedback[0] || question.min}|${high}`);
  };

  const currencyAnswer = answers.find((item) => item.key === "currency");

  let showCurrency = "";
  if (
    question.key === "salary" &&
    currencyAnswer?.feedback &&
    currencyAnswer?.feedback.length > 0
  ) {
    showCurrency = currencyAnswer.feedback[0];
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <CustomInput
          labelText={`Min ${showCurrency ? `(${showCurrency})` : ""}`}
          id={`${question.key}_min`}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: answer?.feedback[0],
            onChange: (e) => onChangeLow(e.target.value),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={6} md={6}>
        <CustomInput
          labelText={`Max ${showCurrency ? `(${showCurrency})` : ""}`}
          id={`${question.key}_min`}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: answer?.feedback[1],
            onChange: (e) => onChangeHigh(e.target.value),
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
