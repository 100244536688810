import Picker from "components/CustomPicker/CustomPicker";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";

export default function TwoInputSelect({ question, answer, onSelect }) {
  const selectorData =
    question.choices?.map((item) => ({ value: item.key, label: item.label })) ||
    [];
  const secondSelectorData =
    question.secondChoices?.map((item) => ({
      value: item.key,
      label: item.label,
    })) || [];

  const selectedFirst = answer?.feedback[0]
    ? [
        {
          value: answer?.feedback[0],
          label:
            question.choices?.find(
              (choice) => choice.key === answer?.feedback[0]
            )?.label || "",
        },
      ]
    : [];

  const selectedSecond = answer?.feedback[0]
    ? [
        {
          value: answer?.feedback[1],
          label:
            question.secondChoices?.find(
              (choice) => choice.key === answer?.feedback[1]
            )?.label || "",
        },
      ]
    : [];

  const onSelectFirstPicker = (newSelected) => {
    onSelect?.(question.key, `${newSelected}|${selectedSecond[0]?.key || ""}`);
  };

  const onSelectSecondPicker = (newSelected) => {
    onSelect?.(question.key, `${selectedFirst[0]?.key || ""}|${newSelected}`);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <Picker
          //   data={selectorData}
          //   selected={selectedFirst}
          //   onSelect={onSelectFirstPicker}
          //   placeholder={question.placeholder}
          //   sheetTitle={question.sheetTitle}
          label={question.placeholder1}
          id={question.key}
          data={selectorData}
          value={selectedFirst[0]}
          onChange={(e) => onSelectFirstPicker(e.target.value)}
        />
      </GridItem>

      <GridItem xs={12} sm={6} md={6}>
        <Picker
          label={question.placeholder2}
          id={question.key}
          data={secondSelectorData}
          value={selectedSecond[0]}
          onChange={(e) => onSelectSecondPicker(e.target.value)}
        />
      </GridItem>
    </GridContainer>
  );
}
